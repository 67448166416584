// this slice is for file uploads
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { IShare } from 'src/types/IShare';
import { IClient } from 'src/types/IClient';
import { ISite } from 'src/types/ISite';
import { IDocument } from 'src/types/IDocument';
import { IProgram } from 'src/types/IProgram';
import { IProject } from 'src/types/IProject';
import { IUser } from 'src/types/IUser';
import { IDocumentCategories } from 'src/types/IDocumentCategories';

interface State {
  isModalOpen: boolean;
  shareObject: IProgram | IClient | ISite | IProject | IUser | IDocument | any;
  shareType: string;
}

const initialState: State = {
  isModalOpen: false,
  shareObject: null,
  shareType: ''
};

const slice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    shareClient(state: State, action: PayloadAction<{ shareObject: IClient }>) {
      const { shareObject } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = shareObject;
      state.shareType = 'Client';
    },

    shareProgram(
      state: State,
      action: PayloadAction<{ shareObject: IProgram }>
    ) {
      const { shareObject } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = shareObject;
      state.shareType = 'Program';
    },

    shareSite(state: State, action: PayloadAction<{ shareObject: ISite }>) {
      const { shareObject } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = shareObject;
      state.shareType = 'Site';
    },

    shareProject(
      state: State,
      action: PayloadAction<{ shareObject: IProject }>
    ) {
      const { shareObject } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = shareObject;
      state.shareType = 'Project';
    },

    shareDocument(
      state: State,
      action: PayloadAction<{ shareObject: IDocument }>
    ) {
      const { shareObject } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = shareObject;
      state.shareType = 'Document';
    },

    shareDocumentsByIds(
      state: State,
      action: PayloadAction<{ ids: number[] }>
    ) {
      const { ids } = action.payload;

      //   state.share = share;
      state.isModalOpen = true;
      state.shareObject = ids;
      state.shareType = 'Documents';
    },

    // shareDocumentSearch(
    //   state: State,
    //   action: PayloadAction<{ shareObject: string }>
    // ) {
    //   const { shareObject } = action.payload;

    //   //   state.share = share;
    //   state.isModalOpen = true;
    //   state.shareObject = shareObject;
    //   state.shareType = 'Document';
    // },

    closeModal(state: State, action: PayloadAction) {
      state.isModalOpen = false;
      state.shareObject = null;
      state.shareType = '';
    }
  }
});

export const reducer = slice.reducer;

export const shareClient = (shareObject: IClient): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.shareClient({ shareObject }));
};

export const shareProgram = (shareObject: IProgram): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.shareProgram({ shareObject }));
};

export const shareSite = (shareObject: ISite): AppThunk => async (dispatch) => {
  dispatch(slice.actions.shareSite({ shareObject }));
};

export const shareProject = (shareObject: IProject): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.shareProject({ shareObject }));
};

export const shareDocument = (shareObject: IDocument): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.shareDocument({ shareObject }));
};

export const shareDocumentByIds = (ids: number[]): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.shareDocumentsByIds({ ids }));
};

// export const shareDocumentSearch = (shareObject: string): AppThunk => async (
//   dispatch
// ) => {
//   dispatch(slice.actions.shareDocumentSearch({ shareObject }));
// };

export const sendShare = (share: IShare): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const closeModal = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
