import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

import LoginView from 'src/views/auth/LoginView';
import RegisterView from 'src/views/auth/RegisterView';
import ForgotView from 'src/views/auth/ForgotView';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: MainLayout,
    component: LoginView
  },
  {
    exact: true,
    path: '/updated-terms',
    layout: MainLayout,
    component: lazy(() => import('src/views/auth/UpdatedTerms'))
  },
  {
    exact: true,
    path: '/terms',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/system/TermsView'))
  },
  {
    exact: true,
    path: '/privacy',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/system/PrivacyView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    layout: MainLayout,
    component: RegisterView
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    layout: MainLayout,
    component: ForgotView
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/home',
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        exact: true,
        path: '/app/clients',
        component: () => <Redirect to="/app/clients/browse" />
      },
      {
        exact: true,
        path: '/app/clients/browse',
        component: lazy(() => import('src/views/clients/BrowseView'))
      },
      {
        exact: true,
        path: '/app/clients/create',
        component: () => <Redirect to="/app/clients/create/0" />
      },
      {
        exact: true,
        path: '/app/clients/create/:step',
        component: lazy(() => import('src/views/clients/CreateView'))
      },
      {
        exact: true,
        path: '/app/clients/edit/:id',
        component: lazy(() => import('src/views/clients/DetailsView'))
      },
      {
        exact: true,
        path: '/app/programs',
        component: () => <Redirect to="/app/programs/browse" />
      },
      {
        exact: true,
        path: '/app/programs/browse',
        component: lazy(() => import('src/views/programs/ProgramBrowseView'))
      },
      {
        exact: true,
        path: '/app/programs/create',
        component: () => <Redirect to="/app/programs/create/0" />
      },
      {
        exact: true,
        path: '/app/programs/create/:step',
        component: lazy(() => import('src/views/programs/CreateView'))
      },
      {
        exact: true,
        path: '/app/programs/create',
        component: lazy(() => import('src/views/programs/CreateView'))
      },
      {
        exact: true,
        path: '/app/programs/edit/:id',
        component: lazy(() => import('src/views/programs/ProgramDetailsView'))
      },
      {
        exact: true,
        path: '/app/sites',
        component: () => <Redirect to="/app/sites/browse" />
      },
      {
        exact: true,
        path: '/app/sites/browse',
        component: lazy(() => import('src/views/sites/BrowseView'))
      },
      {
        exact: true,
        path: '/app/sites/create',
        component: () => <Redirect to="/app/sites/create/0" />
      },
      {
        exact: true,
        path: '/app/sites/create/:step',
        component: lazy(() => import('src/views/sites/CreateView'))
      },
      {
        exact: true,
        path: '/app/sites/edit/:id',
        component: lazy(() => import('src/views/sites/DetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: () => <Redirect to="/app/projects/create/0" />
      },
      {
        exact: true,
        path: '/app/projects/create/:step',
        component: lazy(() => import('src/views/projects/CreateView'))
      },
      {
        exact: true,
        path: '/app/projects/edit/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/users',
        component: () => <Redirect to="/app/users/browse" />
      },
      {
        exact: true,
        path: '/app/users/browse',
        component: lazy(() => import('src/views/users/BrowseView'))
      },
      {
        exact: true,
        path: '/app/users/create',
        component: () => <Redirect to="/app/users/create/0" />
      },
      {
        exact: true,
        path: '/app/users/create/:step',
        component: lazy(() => import('src/views/users/CreateView'))
      },
      {
        exact: true,
        path: '/app/users/edit/:id',
        component: lazy(() => import('src/views/users/DetailsView'))
      },
      {
        exact: true,
        path: '/app/users/edit/:id/:tab',
        component: lazy(() => import('src/views/users/DetailsView'))
      },
      {
        exact: true,
        path: '/app/files/browse',
        component: lazy(() => import('src/views/files/BrowseView'))
      },
      {
        exact: true,
        path: '/app/files',
        component: lazy(() => import('src/views/files/BrowseView'))
      },
      {
        exact: true,
        path: '/app/files/edit',
        component: lazy(() => import('src/views/files/EditView'))
      },
      {
        exact: true,
        path: '/app/files/edit/:documentId',
        component: lazy(() => import('src/views/files/BrowseView'))
      },
      {
        exact: true,
        path: '/app/files/download',
        component: lazy(() => import('src/views/files/DownloadView'))
      },
      {
        exact: true,
        path: '/app/files/download/:documentId',
        component: lazy(() => import('src/views/files/DownloadView'))
      },
      {
        exact: true,
        path: '/app/files/upload',
        component: lazy(() => import('src/views/files/MultiUploadView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
