import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/activity';
import { IRecentActivity } from 'src/types/IRecentActivity';
import { Levels } from 'src/types/Levels';

interface State {
  isLoading: boolean;
  currentActivities: IRecentActivity[];
}

const initialState: State = {
  isLoading: false,
  currentActivities: []
};

const slice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    getRecentActivities(
      state: State,
      action: PayloadAction<{ activities: IRecentActivity[] }>
    ) {
      const { activities } = action.payload;

      state.currentActivities = activities;
      state.isLoading = false;
    },
    isLoading(state: State, action: PayloadAction) {
      state.isLoading = true;
    }
  }
});

export const reducer = slice.reducer;

const queryBase = {
  returnTotal: false,
  pagination: true,
  rowsPerPage: 15,
  pageNumber: 1,
  orderBy: [{ fieldName: 'modifiedDate', OrderType: 'desc' }]
};

export const getRecentActiviesByIdAndLevel = (
  id: number,
  level: 'program' | 'site' | 'project' | 'client'
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());

  const activities = await api.getActivitiesByDynamicQuery({
    ...queryBase,
    where: [
      {
        andOr: '',
        items: [
          {
            andOr: 'and',
            field: `${level}Id`,
            operator: '=',
            Value: `${id}`
          },
          {
            andOr: 'and',
            field: 'activityLevel',
            operator: '=',
            value: level
          }
        ]
      }
    ]
  });

  dispatch(slice.actions.getRecentActivities({ activities }));
};

export const getRecentActiviesByLevel = (level: Levels): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());

  const activities = await api.getActivitiesByDynamicQuery({
    ...queryBase,
    where: [
      {
        andOr: '',
        items: [
          {
            andOr: 'and',
            field:
              level === Levels.DOCUMENT ? 'activityTypeName' : 'activityLevel',
            operator: level === Levels.DOCUMENT ? 'Contains' : '=',
            value: level
          }
        ]
      }
    ]
  });

  dispatch(slice.actions.getRecentActivities({ activities }));
};

export const getRecentActiviesByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());

  const activities = await api.getActivitiesByDynamicQuery({
    ...queryBase,
    userId
  });

  dispatch(slice.actions.getRecentActivities({ activities }));
};

export default slice;
