import React, { useState, useRef, FC } from 'react';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import { Settings as SettingsIcon } from '@material-ui/icons';
import axios from 'axios';
import { capitalize } from 'src/utils/string';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    debug: settings.debug
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  const handleUnauthorizeClick = (): void => {
    axios.defaults.headers.common.Authorization = '';
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="primary"
          variant="standard"
          classes={{ badge: classes.badge }}
        >
          <IconButton color="inherit" onClick={handleOpen} ref={ref}>
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          Debug Settings
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.debug}
                edge="start"
                name="debug"
                onChange={(event) =>
                  handleChange('debug', event.target.checked)
                }
              />
            }
            label="Debug"
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUnauthorizeClick}
          >
            Unauthorize Me
          </Button>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option key={theme} value={theme}>
                {capitalize(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Typography>
            Publish Date: {process.env.REACT_APP_PUBLISH_DATE}
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
