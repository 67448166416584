import axios from 'axios';
import urlBuilder from 'src/utils/urlBuilder';
import { IComment } from 'src/types/IComment';

export const getDocumentCommentsByDocumentId = async (documentId: number) => {
  const response = await axios.get<IComment[]>(
    urlBuilder('Document/GetDocumentCommentsByDocumentId'),
    {
      params: {
        documentId
      }
    }
  );

  return response.data;
};

export const addDocumentComment = async (comment: IComment) => {
  const response = await axios.post<IComment>(
    urlBuilder('Document/AddDocumentComment'),
    comment
  );

  return response.data;
};
