import axios from 'axios';
import Program from 'src/models/Program';
import { IProgram } from 'src/types/IProgram';
import { ISummary } from 'src/types/ISummary';
import urlBuilder from 'src/utils/urlBuilder';
import { queryHelper } from './dynamic-query-helper';
interface IProgramQuery {
  pageNumber: number;
  result: IProgram[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
  pagination: boolean;
  summary: boolean;
}

export const getProgramsByQueryRaw = async (query: any, limit?: number) => {
  return await axios.post<IProgramQuery>(
    urlBuilder('Client/GetProgramsByDynamicQuery'),
    query
  );
};

export const getProgramsByDynamicQuery = async (query: any, limit?: number) => {
  let programs: Program[] = null;

  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1
    };
  }

  const response = await axios.post<IProgramQuery>(
    urlBuilder('Client/GetProgramsByDynamicQuery'),
    query
  );

  programs = response.data.result.map((x) => Program.deserialize(x));

  return programs;
};

export const getPrograms = async () => {
  const programs = await getProgramsByDynamicQuery({});
  return programs;
};

export const getProgramByProgramId = async (programId: number) => {
  const programs = await getProgramsByDynamicQuery({
    where: queryHelper.getProgram(programId).getQuery()
  });
  return programs[0] || null;
};

export const getProgramsByClientId = async (clientId: number) => {
  const programs = await getProgramsByDynamicQuery({
    where: queryHelper.getClient(clientId).getQuery()
  });

  return programs;
};

export const getProgramsBySiteId = async (query, siteId: number) => {
  return await axios.post<IProgram[]>(
    urlBuilder('Client/GetProgramsBySiteId'),
    query,
    {
      params: {
        siteId
      }
    }
  );
};

export const getProgramsByProjectId = async (query, projectId: number) => {
  return await axios.post<IProgram[]>(
    urlBuilder('Client/GetProgramsByProjectId'),
    query,
    {
      params: {
        projectId
      }
    }
  );
};

export const getRecentProgramByUserId = async (userId: number) => {
  const programs = await getProgramsByDynamicQuery({
    userId,
    pagination: true,
    rowsPerPage: 1,
    pageNumber: 1,
    orderBy: [{ fieldName: 'modifiedDate', OrderType: 'desc' }],
    where: [
      {
        andOr: '',
        items: [
          {
            andOr: '',
            field: 'active',
            operator: '=',
            value: `true`
          }
        ]
      }
    ]
  });

  return programs[0] || null;
};

export const getProgramsBySearchValue = async (value: string, extraQuery?) => {
  let query = [];

  if (extraQuery) {
    query = extraQuery;
  }

  query = [
    ...query,
    {
      andOr: query.length === 0 ? '' : 'and',
      items: [
        {
          andOr: '',
          field: 'programName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'programID',
          operator: 'contains',
          value
        }
      ]
    }
  ];

  const programs = await getProgramsByDynamicQuery({
    where: query,
    orderBy: [
      {
        fieldName: 'createdDate',
        orderType: 'desc'
      }
    ]
  });

  return programs;
};

export const getProgramsByUserId = async (userId: number) => {
  const programs = await getProgramsByDynamicQuery({
    pagination: true,
    pageNumber: 1,
    userId
  });

  return programs;
};

export const getProgramSummaryByProgramId = async (programId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetProgramSummaryByProgramId'),
    {
      params: {
        programId
      }
    }
  );

  return response.data;
};

export const createProgram = async (program: IProgram): Promise<Program> => {
  const response = await axios.post<IProgram>(
    urlBuilder('Client/AddProgram'),
    program
  );

  return new Program(response.data);
};

export const deleteProgramByProgramId = async (programId: number) => {
  await axios.post(urlBuilder('Client/DeleteProgram'), null, {
    params: {
      id: programId
    }
  });
};

export const updateProgram = async (program: IProgram) => {
  const response = await axios.post<IProgram>(
    urlBuilder('Client/UpdateProgram'),
    program,
    {
      params: {
        id: program.programID
      }
    }
  );

  return response.data;
};

export const uploadProgramInfoFile = async (file: File, onUploadProgress) => {
  return axios.post(
    urlBuilder('Client/UploadProgramInfoFile'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress
    }
  );
};

export const getProgramImageByClientId = async (programId: number) => {
  try {
    return await axios.get(urlBuilder('Client/GetProgramLogo'), {
      responseType: 'arraybuffer',
      params: {
        programId
      }
    });
  } catch (e) {}
};

export const postProgramImageByProgramId = async (
  programId: number,
  file: File
) => {
  return axios.post(
    urlBuilder('Client/PostProgramLogo'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'image/jpeg'
      },
      params: {
        programId
      }
    }
  );
};
