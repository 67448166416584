import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import UploadBatchCSVButton from './UploadBatchCSVButton';
import LoadingScreen from 'src/components/LoadingScreen';
import {
  Table,
  Grid as TableGrid,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import { capitalize } from 'src/utils/string';
import { Levels } from 'src/types/Levels';
import { useDispatch, useSelector } from 'src/store';
import { getProgramsByQueryRaw } from 'src/slices/program';
import { getSitesByQueryRaw } from 'src/slices/site';
import { getUsersByQueryRaw } from 'src/slices/user';
import Emitter from 'src/services/emitter';

const useStyles = makeStyles(() => ({
  buttons: {
    '& > * + *': {
      marginLeft: 16
    }
  },
  table: {
    height: '50vh',
    overflowY: 'auto',
    overflowX: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  }
}));

interface Props {
  [key: string]: any;
  level?: Levels;
}

const UploadBatchCSVFileForm: FC<Props> = ({
  onClose,
  message = '',
  code = 200,
  data = [],
  level = Levels.PROGRAM
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { request: programRequest } = useSelector((state) => state.programs);
  const { request: siteRequest } = useSelector((state) => state.sites);
  const { request: userRequest } = useSelector((state) => state.users);

  useEffect(() => {
    if (code === 200) {
      // reload table
      if (level === Levels.PROGRAM) {
        Emitter.emit('REFRESH_PROGRAMS_TABLE');
      }
      if (level === Levels.SITE) {
        Emitter.emit('REFRESH_SITES_TABLE');
      }
      if (level === Levels.USER) {
        Emitter.emit('REFRESH_USERS_TABLE');
      }
    }
  }, [code]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h3" style={{ width: '100%' }}>
          Batch Upload ({capitalize(level)})
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {code === 0 && <LoadingScreen />}
            {code === 200 && (
              <Typography variant="h4">File uploaded successfully</Typography>
            )}
            {code === 400 && (
              <Typography variant="h4">File Failed to Upload</Typography>
            )}
            <Typography>{message}</Typography>

            <div className={classes.table}>
              {data.length > 0 && (
                <TableGrid
                  rows={data}
                  columns={[
                    { name: 'validationResult', title: 'Issue' },
                    { name: 'userID', title: 'User ID' },
                    { name: 'firstName', title: 'First Name' },
                    { name: 'lastName', title: 'Last Name' },
                    { name: 'clientID', title: 'Client ID' },
                    { name: 'email', title: 'Email' },
                    { name: 'phone', title: 'Phone' },
                    { name: 'userType', title: 'User Type' },
                    { name: 'notes', title: 'Notes' }
                  ]}
                >
                  <Table
                    columnExtensions={[
                      { columnName: 'validationResult', wordWrapEnabled: true }
                    ]}
                    rowComponent={({ row, ...restProps }) => (
                      // @ts-ignore
                      <Table.Row
                        {...restProps}
                        style={{
                          backgroundColor: row?.validationResult
                            ? '#ffcccb'
                            : null
                        }}
                      />
                    )}
                  />
                  <TableHeaderRow />
                </TableGrid>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box p={2} display="flex" justifyContent="flex-end">
        <Box pl={2}>
          {code === 200 ? (
            <Button color="primary" variant="contained" onClick={onClose}>
              Close
            </Button>
          ) : (
            <Box className={classes.buttons}>
              <Button onClick={onClose}>Cancel</Button>
              <UploadBatchCSVButton level={level}>
                Re-Upload File
              </UploadBatchCSVButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UploadBatchCSVFileForm;
