import React, { ReactNode } from 'react';
import type { FC } from 'react';
import { makeStyles, Button, SvgIcon } from '@material-ui/core';
import type { Theme } from 'src/theme';
import { setBatchCSVFile } from 'src/slices/batch-csv-manager';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from 'src/store';
import Can from 'src/components/Security/Can';
import { Levels } from 'src/types/Levels';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  input: {
    display: 'none'
  }
}));

const UploadBatchCSVButton: FC<{
  level: Levels;
  children?: ReactNode;
}> = ({ level, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFileSelect = (event) => {
    dispatch(setBatchCSVFile(event.target.files, level));
  };

  return (
    <>
      <input
        className={classes.input}
        id={`csv-upload-button-${level}`}
        accept=".csv,text/csv" // https://stackoverflow.com/questions/4328947/limit-file-format-when-using-input-type-file
        multiple
        type="file"
        onChange={handleFileSelect}
      />
      <label htmlFor={`csv-upload-button-${level}`}>
        <Can
          perform={`${level}s:batch_upload`}
          yes={() => (
            <Button
              color="primary"
              variant="contained"
              component="span"
              startIcon={
                <SvgIcon fontSize="small">
                  <CloudUploadIcon />
                </SvgIcon>
              }
            >
              {children ? children : 'CSV Batch Upload'}
            </Button>
          )}
        />
      </label>
    </>
  );
};

export default UploadBatchCSVButton;
