import axios from 'axios';
import { IRecentActivity } from 'src/types/IRecentActivity';
import urlBuilder from 'src/utils/urlBuilder';

interface IRecentActivityQuery {
  pageNumber: number;
  result: IRecentActivity[];
  rowsPerPage: number;
  totalPage: number;
  totalRecords: number;
  userId: number;
  programId: number;
  projectId: number;
  siteId: number;
  clientId: number;
}

export const getActivitiesByDynamicQuery = async (query: any) => {
  const response = await axios.post<IRecentActivityQuery>(
    urlBuilder('User/GetActivitiesByDynamicQuery'),
    query
  );

  return response.data.result;
};
