import React, { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import { Backdrop, makeStyles, Theme } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';

interface Props {
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff'
  },
  card: {
    width: '50%',
    maxHeight: '75vh',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  }
}));

const UpdatedTerms: FC<Props> = (props) => {
  const classes = useStyles();
  const [isModalOpen, setModalopen] = useState(false);
  const { user } = useAuth();

  const handleClose = () => {
    // setModalopen(false);
  };

  // useEffect(() => {
  //   if (!user.acceptedTermsAndConditions) setModalopen(true);
  // }, [user]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Backdrop
      className={classes.backdrop}
      open={isModalOpen}
      onClick={handleClose}
    >
      Terms Here
    </Backdrop>
  );
};

export default UpdatedTerms;
