import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import * as api from 'src/apis/program';
import { ISummary } from 'src/types/ISummary';
import { IRecentActivity } from 'src/types/IRecentActivity';
import { getProjectStatusSummaryByProgramId } from './project';
import Program from 'src/models/Program';
interface ProgramsState {
  newProgram: Program;

  program: Program;
  currentProgram: Program;

  programs: Program[];
  currentPrograms: Program[];
  isLoading: boolean;

  currentProgramSummary: ISummary;
  isLoadingSummary: boolean;

  currentActivities: IRecentActivity[];
  isLoadingActivities: boolean;
  isLoadingRecentProgram: boolean;

  response: any;
  request: any;
}

const initialState: ProgramsState = {
  newProgram: null,

  program: null,
  currentProgram: null,

  programs: [],
  currentPrograms: [],
  isLoading: false,

  currentProgramSummary: null,
  isLoadingSummary: false,

  currentActivities: [],
  isLoadingActivities: false,

  isLoadingRecentProgram: false,

  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {}
};

const slice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setRequest(
      state: ProgramsState,
      action: PayloadAction<{ request: any; id?: any }>
    ) {
      const { request } = action.payload;
      state.request = request;
      state.isLoading = false;
    },

    setResponse(state: ProgramsState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.response = response;
      state.isLoading = false;
    },

    setProgram(
      state: ProgramsState,
      action: PayloadAction<{ program: Program }>
    ) {
      const { program } = action.payload;
      state.program = program;
      state.isLoading = false;
    },

    setPrograms(
      state: ProgramsState,
      action: PayloadAction<{ programs: Program[] }>
    ) {
      const { programs } = action.payload;
      state.programs = programs;
      state.isLoading = false;
    },

    setCurrentProgram(
      state: ProgramsState,
      action: PayloadAction<{ program: Program }>
    ) {
      const { program } = action.payload;
      state.currentProgram = program;
      state.isLoading = false;
    },

    setCurrentPrograms(
      state: ProgramsState,
      action: PayloadAction<{ programs: Program[] }>
    ) {
      const { programs } = action.payload;
      state.currentPrograms = programs;
      state.isLoading = false;
    },

    setRecentProgram(
      state: ProgramsState,
      action: PayloadAction<{ program: Program }>
    ) {
      const { program } = action.payload;
      state.currentProgram = program;
      state.isLoading = false;
    },

    setCurrentProgramSummary(
      state: ProgramsState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;

      state.currentProgramSummary = summary;
      state.isLoadingSummary = false;
    },

    createProgram(
      state: ProgramsState,
      action: PayloadAction<{ program: Program }>
    ) {
      const { program } = action.payload;

      state.programs = [...state.programs, program];
      state.newProgram = program;
    },
    updateProgram(
      state: ProgramsState,
      action: PayloadAction<{ program: Program }>
    ) {
      const { program } = action.payload;

      state.programs = state.programs.map((x) =>
        x.programID === program.programID ? program : x
      );

      state.currentProgram = program;
    },
    deleteProgram(
      state: ProgramsState,
      action: PayloadAction<{ programID: number }>
    ) {
      const { programID } = action.payload;

      state.programs = state.programs.filter((x) => x.programID != programID);
    },
    isLoading(state: ProgramsState, action: PayloadAction) {
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isLoading = true;
    },
    isLoadingSummary(state: ProgramsState, action: PayloadAction) {
      state.isLoadingSummary = true;
    },
    isLoadingActivities(state: ProgramsState, action: PayloadAction) {
      state.isLoadingActivities = true;
    },

    getRecentActivities(
      state: ProgramsState,
      action: PayloadAction<{ activities: IRecentActivity[] }>
    ) {
      const { activities } = action.payload;

      state.currentActivities = activities;
      state.isLoadingActivities = false;
    },
    setIsLoadingRecentProgram(
      state: ProgramsState,
      action: PayloadAction<{ value: boolean }>
    ) {
      const { value } = action.payload;
      state.isLoadingRecentProgram = value;
    },
    reset(state: ProgramsState, action: PayloadAction<{}>) {
      state.currentProgram = null;
      state.currentProgramSummary = null;
    }
  }
});

export const reducer = slice.reducer;
export const getProgramsByQueryRaw = (baseQuery): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProgramsByQueryRaw(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(slice.actions.setRequest({ request: baseQuery }));
};

export const getProgramsBySiteId = (baseQuery, siteId): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProgramsBySiteId(baseQuery, siteId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

export const getProgramsByProjectId = (
  baseQuery,
  projectId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getProgramsByProjectId(baseQuery, projectId);
  dispatch(slice.actions.setResponse({ response }));
  //dispatch(slice.actions.setRequest({baseQuery, programId, }));
};

// old

export const getProgramByProgramId = (programId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { programs } = getState();
  let program = programs.programs.find((x) => x.programId === programId);
  dispatch(slice.actions.isLoading());

  if (!program) {
    program = await api.getProgramByProgramId(programId);
  }

  if (program) await program.loadAvatar();

  dispatch(
    slice.actions.setCurrentProgram({
      program
    })
  );
};

export const getProgramsByClientId = (clientId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const programs = await api.getProgramsByClientId(clientId);
  dispatch(slice.actions.setCurrentPrograms({ programs }));
};

export const getProgramsByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const programs = await api.getProgramsByUserId(userId);
  dispatch(slice.actions.setCurrentPrograms({ programs }));
};

export const getProgramSummaryByProgramId = (
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getProgramSummaryByProgramId(programId);
  dispatch(slice.actions.setCurrentProgramSummary({ summary }));
};

export const createProgram = ($program: Program): AppThunk => async (
  dispatch
) => {
  const program = await api.createProgram($program);
  dispatch(slice.actions.createProgram({ program }));
};

export const updateProgram = (program: Program): AppThunk => async (
  dispatch
) => {
  const updatedProgram = await api.updateProgram(program.serialize());
  dispatch(
    slice.actions.updateProgram({
      program: new Program({
        ...program,
        ...updatedProgram
      })
    })
  );
};

export const deleteProgramByProgramId = (programId: number): AppThunk => async (
  dispatch
) => {
  await api.deleteProgramByProgramId(programId);
};

export const getRecentProgramByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.setIsLoadingRecentProgram({ value: true }));
  const program = await api.getRecentProgramByUserId(userId);
  dispatch(slice.actions.setRecentProgram({ program }));
  if (program?.programID) {
    dispatch(getProgramSummaryByProgramId(program?.programID));
    dispatch(getProjectStatusSummaryByProgramId(program?.programID));
  }
  dispatch(slice.actions.setIsLoadingRecentProgram({ value: false }));
};

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.reset({}));
};

export default slice;
