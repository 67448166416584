import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Terms from 'src/components/Terms';
import Privacy from 'src/components/Privacy';
import logo from 'src/assets/bv-logo-coloured.svg';

import {
  Grid,
  makeStyles,
  Paper,
  Hidden,
  Divider,
  Box,
  Dialog,
  Button
} from '@material-ui/core';

interface CrestProps {
  fullWidth?: boolean;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  paper: {
    width: ({ fullWidth }: CrestProps) => (fullWidth ? 1200 : 560),
    minHeight: 0,
    maxHeight: '90%',
    overflowY: 'auto',
    overflowX: 'auto',
    background: '#fff',
    padding: theme.spacing(5),
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  },
  logoContainer: {
    textAlign: 'center',
    paddingBottom: 20
  },
  logo: {
    width: '100%',
    maxWidth: 71
  },
  gridContainer: {
    height: '100%'
  },
  footer: {
    fontSize: 11,
    lineHeight: '16px',
    color: '#808080',
    padding: theme.spacing(1, 0, 0, 0)
  }
}));

const Crest: FC<CrestProps> = ({ fullWidth, children, ...rest }) => {
  const classes = useStyles({ fullWidth });
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Dialog open={showTerms} onBackdropClick={() => setShowTerms(false)}>
        <Box m={2}>
          <Terms />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowTerms(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Dialog open={showPrivacy} onBackdropClick={() => setShowPrivacy(false)}>
        <Box m={2}>
          <Privacy />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowPrivacy(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems="stretch"
          direction="row"
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={2} className={classes.logoContainer}>
            <img
              src={logo}
              alt="bv logo"
              className={classes.logo}
              width="71"
              height="88"
            />
          </Grid>
          <Hidden smDown>
            <Grid item md={1}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
          </Hidden>
          <Grid item md xs={12}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

Crest.propTypes = {
  children: PropTypes.node
};

export default Crest;
