import React from 'react';
import type { FC } from 'react';
import Forgot from './Forgot';
import Crest from '../Crest';

const ForgotView: FC = () => {
  return (
    <Crest>
      <Forgot />
    </Crest>
  );
};

export default ForgotView;
