import React, { FC } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Avatar,
  Tooltip
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import { IUser } from 'src/types/IUser';
import { getInitials } from 'src/utils/string';
import { Warning } from '@material-ui/icons';
import { IShareUser } from './@types/ISharedUser';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 40,
    width: 40
  },
  userItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10,
    color: theme.palette.colors.caution
  }
}));

export interface Props {
  [key: string]: any;
  user: IShareUser;
  onRemove?: (e: any, user: IUser) => void;
}

const ShareListItem: FC<Props> = ({ onRemove, user }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Avatar alt="User" src={user.avatar}>
              {!user.avatar &&
                getInitials(`${user.firstName} ${user.lastName}`)}
            </Avatar>

            <Box pl={2} className={classes.userItem}>
              {user!.accessDenied && (
                <Tooltip
                  title={
                    user.error ??
                    'Warning: User does not have access to all files'
                  }
                >
                  <Warning className={classes.icon} />
                </Tooltip>
              )}
              <Typography variant="body2">
                {user.firstName} {user.lastName}
              </Typography>
            </Box>

            <Box pl={2}>
              <Typography variant="body1">{user.email}</Typography>
            </Box>
            <Box flexGrow={1} />
            <Box mr={2} textAlign="end">
              <Button
                color="primary"
                component="span"
                onClick={(e) => onRemove(e, user)}
              >
                REMOVE
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareListItem;
