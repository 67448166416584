import React, { useCallback, useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import columns from './columns';

import {
  Grid,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import LoadingScreen from 'src/components/LoadingScreen';
import { IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import { MenuColumn, IMenuColumn } from '../@plugins/MenuColumn';
import { FileSizeTypeProvider } from '../@components/providers/FileSizeTypeProvider';
import { Checkbox, TextField, Tooltip } from '@material-ui/core';
import ProgressBar from 'src/components/ProgressBar';

import { useSelector } from 'src/store';
import { Autocomplete } from '@material-ui/lab';
import useAuth from 'src/hooks/useAuth';
import Can from 'src/components/Security/Can';
import { Warning } from '@material-ui/icons';
import fileSize from 'src/utils/fileSize';
export interface MultiUploadTableProps {
  [key: string]: any;
  menuActions?: (row: any) => IMenuColumn[];
}

const CellTextField = ({ onCommitChanges, row }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(row.description);
  }, [row.description]);

  return (
    <TextField
      fullWidth
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onBlur={(event) => {
        const changed = {
          [row.id]: { description: event.target.value }
        };
        onCommitChanges({
          changed
        });
      }}
    />
  );
};

const MultiUploadTable: FC<MultiUploadTableProps> = ({
  rows = [],
  isLoading = false,
  selection = [],
  setSelection = null,
  menuActions,
  onCommitChanges = null
}) => {
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [rowChanges, setRowChanges] = useState({});

  const {
    documentCategories,
    assessmentTypeList,
    fileStatusList
  } = useSelector((state) => state.lookups);

  const { user: loggedInUser } = useAuth();

  // let [defaultColumns, setDefaultColumns] = useState(columns);

  let defaultColumns = columns;
  if (loggedInUser.isExternal)
    defaultColumns = columns.filter(
      (column) =>
        !['classification', 'assessmentType', 'status', 'internal'].includes(
          column.name
        )
    );

  const TableComponent = useCallback(
    (props) => {
      return isLoading ? (
        <VirtualTable.Table {...props}>
          <LoadingScreen />
        </VirtualTable.Table>
      ) : (
        <VirtualTable.Table {...props} style={{ marginBottom: '0px' }} />
      );
    },
    [isLoading]
  );

  const [editingStateColumnExtensions] = useState([
    { columnName: 'filename', editingEnabled: false },
    { columnName: 'fileSize', editingEnabled: false },
    { columnName: 'progress', editingEnabled: false },
    { columnName: 'active', editingEnabled: false }
  ]);

  const Cell = useCallback(
    (props) => {
      const { column, value, style, row } = props;

      let content = value;

      if (column.name === 'validation') {
        content = (
          <>
            {!row.isValid() && (
              <Tooltip
                title={row.validate().reduce((acc, validation) => {
                  const [key, value] = Object.entries(validation)[0];
                  return [...acc, <p>{value}</p>];
                }, [])}
              >
                <Warning style={{ color: 'orange' }} />
              </Tooltip>
            )}
          </>
        );
      }

      if (column.name === 'active') {
        content = (
          <Checkbox
            checked={row.active}
            onChange={(event) => {
              const changed = {
                [row.id]: { active: event.target.checked }
              };
              onCommitChanges({
                changed
              });
            }}
          />
        );
      }

      if (column.name === 'internal') {
        content = (
          <Checkbox
            checked={row.internal}
            onChange={(event) => {
              const changed = {
                [row.id]: { internal: event.target.checked }
              };
              onCommitChanges({
                changed
              });
            }}
          />
        );
      }

      if (column.name === 'description') {
        content = <CellTextField onCommitChanges={onCommitChanges} row={row} />;
      }

      if (column.name === 'fileSize') {
        content = fileSize(value, true);
      }

      if (column.name === 'classification') {
        content = (
          <Can
            perform="documents:classification"
            yes={() => (
              <Autocomplete
                options={documentCategories}
                value={
                  documentCategories.filter(
                    (x) => x.categoryName === row.classification
                  )[0] || ''
                }
                onChange={(e: object, value: any | null) => {
                  const changed = {
                    [row.id]: {
                      classification: value?.categoryName,
                      classificationId: value?.documentCategoryID
                    }
                  };

                  onCommitChanges({
                    changed
                  });
                }}
                fullWidth
                getOptionLabel={(option) => option.categoryName || ''}
                getOptionSelected={(option, value) =>
                  option.categoryName === value.categoryName
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    InputLabelProps={{
                      disableAnimation: true
                    }}
                    name="categoryName"
                    label="Classification"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          />
        );
      }

      if (column.name === 'assessmentType') {
        content = (
          <>
            {!loggedInUser.isExternal && (
              <Autocomplete
                options={assessmentTypeList}
                value={
                  assessmentTypeList.filter(
                    (x) => x.assessmentTypeName === row.assessmentType
                  )[0] || ''
                }
                onChange={(e: object, value: any | null) => {
                  const changed = {
                    [row.id]: {
                      assessmentType: value?.assessmentTypeName,
                      assessmentTypeId: value?.assessmentTypeID
                    }
                  };

                  onCommitChanges({
                    changed
                  });
                }}
                fullWidth
                getOptionLabel={(option) => option.assessmentTypeName || ''}
                getOptionSelected={(option, value) =>
                  option.assessmentTypeName === value.assessmentTypeName
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    InputLabelProps={{
                      disableAnimation: true
                    }}
                    name="assessmentTypeName"
                    label="Assessment Type"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          </>
        );
      }

      if (column.name === 'status' && !loggedInUser.isExternal) {
        content = (
          <Autocomplete
            key={`${row.id}_autocomplete_status`}
            options={fileStatusList}
            value={
              fileStatusList.filter(
                (x) => x.fileStatusID === row.statusId
              )[0] || ''
            }
            onChange={(e: object, value: any | null) => {
              const changed = {
                [row.id]: {
                  status: value?.name,
                  statusId: value?.fileStatusID
                }
              };

              onCommitChanges({
                changed
              });
            }}
            fullWidth
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) =>
              option.fileStatusID === value.fileStatusID
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputLabelProps={{
                  disableAnimation: true
                }}
                name="fileStatusID"
                label="File Status"
                variant="outlined"
                size="small"
              />
            )}
          />
        );
      }

      return (
        <VirtualTable.Cell {...props} style={style}>
          {content}
        </VirtualTable.Cell>
      );
    },
    [rows]
  );

  const getRowId = useCallback((row) => row.id, [rows]);

  const columnsWidths = useMemo(
    () =>
      defaultColumns.map((column) => ({
        columnName: column.name,
        width: column.width
      })),
    [defaultColumns]
  );

  return (
    <>
      <Grid rows={rows} columns={defaultColumns} getRowId={getRowId}>
        {/* <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          onCommitChanges={onCommitChanges}
          columnExtensions={editingStateColumnExtensions}
        /> */}
        <SelectionState
          selection={selection}
          onSelectionChange={setSelection}
        />
        <IntegratedSelection />
        <FileSizeTypeProvider for={['fileSize']} />
        <VirtualTable
          tableComponent={TableComponent}
          cellComponent={Cell}
          columnExtensions={columnsWidths}
          height={'100%'}
        />
        <TableHeaderRow />
        <MenuColumn menuActions={menuActions} />
        {/* <TableEditRow /> */}
        {/* <TableInlineCellEditing /> */}

        <TableSelection showSelectAll />

        <TableFixedColumns
          rightColumns={[MenuColumn.MENU_COLUMN_TYPE, 'menu']}
        />
      </Grid>
    </>
  );
};

export default MultiUploadTable;
