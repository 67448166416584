import React from 'react';
import type { FC } from 'react';
import Login from './Login';
import Crest from '../Crest';

const LoginView: FC = () => {
  return (
    <Crest>
      <Login />
    </Crest>
  );
};

export default LoginView;
