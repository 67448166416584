import { Roles } from 'src/types/IRole';
import { Levels } from 'src/types/Levels';

export const queryHelper = {
  query: [],
  getClient: function (clientId: number, query = [], operator?) {
    return this.getRecordsByLevelandId(
      Levels.CLIENT,
      clientId,
      query,
      operator
    );
  },
  getProgram: function (programId: number, query = [], operator?) {
    return this.getRecordsByLevelandId(
      Levels.PROGRAM,
      programId,
      query,
      operator
    );
  },
  getSite: function (siteId: number, query = [], operator?) {
    return this.getRecordsByLevelandId(Levels.SITE, siteId, query, operator);
  },
  getProject: function (projectId: number, query = [], operator?) {
    return this.getRecordsByLevelandId(
      Levels.PROJECT,
      projectId,
      query,
      operator
    );
  },
  getUser: function (userId: number, query = [], operator?) {
    return this.getRecordsByLevelandId(Levels.USER, userId, query, operator);
  },
  getDocument: function (id: number, level: Levels | any, query) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'linkId',
            operator: '=',
            value: `${id}`
          },
          {
            andOr: 'and',
            field: 'linkLevel',
            operator: '=',
            value: `${level}`
          }
        ]
      }
    ];

    return this;
  },
  getSectionDocuments: function (
    primaryId: number,
    primaryLevel: Levels,
    secondaryLevel: Levels,
    query
  ) {
    const getFieldName = (level: Levels): string => {
      switch (level) {
        case Levels.CLIENT:
          return 'clientID';
        case Levels.PROGRAM:
          return 'programID';
        case Levels.SITE:
          return 'siteID';
        case Levels.PROJECT:
          return 'projectID';
        default:
          return '';
      }
    };

    let items = [];

    if (secondaryLevel !== Levels.ALL) {
      items = [
        {
          andOr: 'and',
          field: getFieldName(secondaryLevel),
          operator: '>',
          value: '0'
        },
        {
          andOr: 'and',
          field: 'linkLevel',
          operator: '=',
          value: secondaryLevel
        }
      ];
    }

    // if (secondaryLevel === Levels.ALL) {
    //   items = [
    //     Levels.CLIENT,
    //     Levels.PROGRAM,
    //     Levels.SITE,
    //     Levels.PROJECT
    //   ].reduce((acc, level) => {
    //     return [
    //       ...acc,

    //       {
    //         andOr: 'and',
    //         field: 'linkLevel',
    //         operator: '=',
    //         value: level
    //       }
    //     ];

    //     return acc;
    //   }, []);
    // }

    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: getFieldName(primaryLevel),
            operator: '=',
            value: `${primaryId}`
          },
          ...items
        ]
      }
    ];

    return this;
  },
  getDocumentByIds: function (ids: number[], query) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: ids.reduce((acc, val) => {
          return [
            ...acc,
            {
              andOr: acc.length === 0 ? '' : 'or',
              field: 'documentId',
              operator: '=',
              value: `${val}`
            }
          ];
        }, [])
      }
    ];

    return this;
  },
  getRecordsByLevelandId: function (
    level: Levels,
    id: number,
    query = [],
    operator?
  ) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query, operator),
        items: [
          {
            andOr: '',
            field: `${level.toLocaleLowerCase()}Id`,
            operator: '=',
            value: `${id}`
          }
        ]
      }
    ];

    return this;
  },

  getInternal: function (query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'roleID',
            operator: '=',
            value: `${Roles.INTERNAL_USER_ADMIN}`
          },
          {
            andOr: 'or',
            field: 'roleID',
            operator: '=',
            value: `${Roles.INTERNAL_USER_FULL_ACCESS}`
          },
          {
            andOr: 'or',
            field: 'roleID',
            operator: '=',
            value: `${Roles.INTERNAL_USER_READ_ONLY}`
          }
        ]
      }
    ];

    return this;
  },
  getExternal: function (query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'roleID',
            operator: '=',
            value: `${Roles.EXTERNAL_USER_ADMIN}`
          },
          {
            andOr: 'or',
            field: 'roleID',
            operator: '=',
            value: `${Roles.EXTERNAL_USER_FULL_ACCESS}`
          },
          {
            andOr: 'or',
            field: 'roleID',
            operator: '=',
            value: `${Roles.EXTERNAL_USER_READ_ONLY}`
          }
        ]
      }
    ];

    return this;
  },
  getConfidential: function (confidential = true, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'confidential',
            operator: '=',
            value: `${confidential}`
          }
        ]
      }
    ];

    return this;
  },
  getConfidentialAccess: function (confidential = true, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'confidentialAccess',
            operator: '=',
            value: `${confidential}`
          },
          {
            andOr: 'or',
            field: 'isClientConfidential',
            operator: '=',
            value: `${confidential}`
          },
          {
            andOr: 'or',
            field: 'isProgramConfidential',
            operator: '=',
            value: `${confidential}`
          },
          {
            andOr: 'or',
            field: 'isSiteConfidential',
            operator: '=',
            value: `${confidential}`
          },
          {
            andOr: 'or',
            field: 'isProjectConfidential',
            operator: '=',
            value: `${confidential}`
          }
        ]
      }
    ];

    return this;
  },
  getActiveRecord: function (activeRecord = true, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'active',
            operator: '=',
            value: `${activeRecord}`
          }
        ]
      }
    ];

    return this;
  },
  getUsersAccessByClientLevel: function (clientId: number, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'clientId',
            operator: '=',
            value: `${clientId}`
          },
          {
            andOr: 'and',
            field: 'programId',
            operator: '=',
            value: 'null'
          },
          {
            andOr: 'and',
            field: 'siteId',
            operator: '=',
            value: 'null'
          },
          {
            andOr: 'and',
            field: 'projectId',
            operator: '=',
            value: 'null'
          }
        ]
      }
    ];

    return this;
  },
  getUsersAccessByProgramLevel: function (programId: number, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'programId',
            operator: '=',
            value: `${programId}`
          },
          {
            andOr: 'and',
            field: 'siteId',
            operator: '=',
            value: 'null'
          },
          {
            andOr: 'and',
            field: 'projectId',
            operator: '=',
            value: 'null'
          }
        ]
      }
    ];

    return this;
  },
  getUsersAccessBySiteLevel: function (siteId: number, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'programId',
            operator: '=',
            value: 'null'
          },
          {
            andOr: 'and',
            field: 'siteId',
            operator: '=',
            value: `${siteId}`
          },
          {
            andOr: 'and',
            field: 'projectId',
            operator: '=',
            value: 'null'
          }
        ]
      }
    ];

    return this;
  },
  getUsersAccessByprojectLevel: function (projectId: number, query = []) {
    return this.getRecordsByLevelandId(Levels.PROJECT, projectId, query);
  },
  addFilters: function (filters = [], query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: filters.reduce((acc, { columnName, value }) => {
          //TODO: future create interface for filters and have a type = Date to determine to apply the date filter or DTO before it gets used
          const isDate = [
            'effectiveStartDate',
            'dueDate',
            'awardDate',
            'deliveryDate',
            'contractedDTC',
            'dtpDate',
            'actualDeliveryDate',
            'createdDate',
            'modifiedDate'
          ].includes(columnName);

          if (value === '') return acc;

          if (isDate) {
            return [
              ...acc,
              {
                andOr: acc.length === 0 ? '' : 'and',
                field: `${columnName}`,
                operator: '>=',
                value: `${value} 00:00:00`
              },
              {
                andOr: 'and',
                field: `${columnName}`,
                operator: '<=',
                value: `${value} 23:59:59`
              }
            ];
          } else {
            return [
              ...acc,
              {
                andOr: acc.length === 0 ? '' : 'and',
                field: `${columnName}`,
                operator: 'contains',
                value: `${value}`
              }
            ];
          }
        }, [])
      }
    ];

    return this;
  },
  searchColumns: function (columns = [], searchValue = '', query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: columns.reduce((acc, column) => {
          return [
            ...acc,
            {
              andOr: this.andOr(acc, 'or'),
              field: `${column?.name}`,
              operator: 'contains',
              value: `${searchValue}`
            }
          ];
        }, [])
      }
    ];

    return this;
  },
  getEmailConfirmed: function (confirmed = true, query = []) {
    this.query = [
      ...this.query,
      ...query,
      {
        andOr: this.andOr(query),
        items: [
          {
            andOr: '',
            field: 'emailConfirmed',
            operator: '=',
            value: `${confirmed}`
          }
        ]
      }
    ];

    return this;
  },
  andOr: function (query, operator = 'and'): string {
    return this.query.length > 0 || query.length > 0 ? operator : '';
  },
  getQuery: function (): any[] {
    const _query = Object.assign(this.query, []);
    this.query = [];
    return _query;
  }
};
