// this slice is for file uploads
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { uploadSiteInfoFile } from 'src/apis/site';
import { uploadProgramInfoFile } from 'src/apis/program';
import { uploadUserInfoFile } from 'src/apis/user';
import { Levels } from 'src/types/Levels';

import * as api from 'src/apis/batch-upload';

export const getCurrentFile = () => {
  return null;
};

export let currentBatchCSVFile: File = getCurrentFile();
interface FilesState {
  isModalOpen: boolean;
  progress: number;
  response: {
    code?: number;
    message?: string;
    data?: [];
  };
  level: Levels;
}

const initialState: FilesState = {
  isModalOpen: false,
  progress: 0,
  response: {},
  level: null
};

const slice = createSlice({
  name: 'batchCsvManager',
  initialState,
  reducers: {
    setBatchCSVFile(
      state: FilesState,
      action: PayloadAction<{ files: File[]; level: Levels }>
    ) {
      const { files, level } = action.payload;
      const file = files[0];

      currentBatchCSVFile = file as File;
      state.isModalOpen = true;
      state.response = {
        code: 0,
        message: '',
        data: []
      };

      state.level = level;
    },
    closeModal(state: FilesState, action: PayloadAction) {
      state.isModalOpen = false;
      state.response = {
        code: 0,
        message: '',
        data: []
      };
      currentBatchCSVFile = null;
    },
    updateProgress(
      state: FilesState,
      action: PayloadAction<{ progress: number }>
    ) {
      const { progress } = action.payload;
      state.progress = progress;
    },
    updateMessage(state: FilesState, action: PayloadAction<{ response: any }>) {
      const { response } = action.payload;
      state.response = {
        code: response?.code || 0,
        message: response?.message || '',
        data: response?.data || []
      };
    }
  }
});

export const reducer = slice.reducer;

export const setBatchCSVFile = (
  files: File[],
  level: Levels
): AppThunk => async (dispatch) => {
  await dispatch(slice.actions.setBatchCSVFile({ files, level }));
  dispatch(uploadFile(level));
};

export const closeModal = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const uploadFile = (level: Levels): AppThunk => async (dispatch) => {
  if (level === Levels.SITE) upload(uploadSiteInfoFile, dispatch);
  if (level === Levels.PROGRAM) upload(uploadProgramInfoFile, dispatch);
  if (level === Levels.USER) upload(uploadUserInfoFile, dispatch);
};

const upload = async (uploadApi, dispatch) => {
  try {
    const response = await uploadApi(currentBatchCSVFile, (event) => {
      dispatch(
        slice.actions.updateProgress({
          progress: Math.round((100 * event.loaded) / event.total)
        })
      );
    });

    dispatch(slice.actions.updateMessage({ response: response.data }));
  } catch (err) {
    // submitError(err);
    dispatch(
      slice.actions.updateMessage({
        response: err?.response ? err.response?.data : err
      })
    );
  }
};

export const getTemplateFile = async (level: Levels) => {
  await api.getTemplateFile(level);
};

export default slice;
