import React from 'react';
import type { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from 'src/theme';
import Lottie from 'src/components/Lottie';

import lottieAnimation from 'src/assets/lotties/bv-animation.json';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen: FC<{ style?: any }> = ({ style }) => {
  const classes = useStyles();

  return (
    <div>
      <Lottie
        data={lottieAnimation}
        style={{ width: '96px', margin: 'auto', ...style }}
      />
    </div>
  );
};

export default LoadingScreen;
