import React, { useEffect, useState, FC } from 'react';
import dayjs from 'dayjs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Divider,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';
import NotificationItem from './NotificationItem';
import {
  ExpandMore as ExpandMoreIcon,
  Remove as RemoveIcon
} from '@material-ui/icons';

import { IRecentActivity } from 'src/types/IRecentActivity';
import { format, isToday, isYesterday, DATE_FORMATS } from 'src/utils/date';
import { groupBy } from 'src/utils/object';

interface NotificationPanelProps {
  className?: string;
  recentActivities?: IRecentActivity[];
  onMinimize?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc(100vh - 170px)',
    overflow: 'scroll'
  },
  accordion: {
    boxShadow: 'none'
  },
  accordionDetails: {
    padding: 0,
    display: 'unset'
  }
}));

const NotificationPanel: FC<NotificationPanelProps> = ({
  className,
  recentActivities = [],
  onMinimize,
  ...rest
}) => {
  const classes = useStyles();
  const [activities, setActivities] = useState([]);

  const getGrouped = (recentActivities) => {
    var groups = groupBy(recentActivities, (x) =>
      dayjs(x.modifiedDate).startOf('day').format()
    );

    var results = Object.entries(groups).map(([day, activities]) => ({
      day,
      activities
    }));

    return results;
  };

  useEffect(() => {
    setActivities(getGrouped(recentActivities));
  }, [recentActivities]);

  return (
    <>
      <PerfectScrollbar>
        {activities.map((x, i) => (
          <Accordion
            square
            className={classes.accordion}
            key={x.day}
            defaultExpanded={i === 0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {isToday(x.day) ? 'Today - ' : ''}
                {isYesterday(x.day) ? 'Yesterday - ' : ''}
                {format(x.day, DATE_FORMATS.SIMPLE_DATE)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ width: '100%', display: 'block', padding: 0 }}
            >
              <Divider />
              {x.activities.map((y) => (
                <NotificationItem key={y.activityID} recentActivity={y} />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </PerfectScrollbar>
    </>
  );
};

export default NotificationPanel;
