import axios from 'axios';
import { IProjectStatusList } from 'src/types/IProjectStatusList';
import { IProgramStatusList } from 'src/types/IProgramStatusList';
import { IActivityTypeList } from 'src/types/IActivityTypeList';
import { IAssessmentTypeList } from 'src/types/IAssessmentTypeList';
import { IDocumentCategories } from 'src/types/IDocumentCategories';
import urlBuilder from 'src/utils/urlBuilder';
import { ICountry } from 'src/types/ICountry';
import { IRole } from 'src/types/IRole';
import { INotificationFrequencyList } from 'src/types/INotificationFrequencyList';
import { IFileStatusList } from 'src/types/IFileStatusList';
import { ISiteTypeList } from 'src/types/ISiteTypeList';
import { IClientTypeList } from 'src/types/IClientTypeList';
import { IGridColumnSizeList } from 'src/types/IGridColumnSizeList';

export const getFileStatusList = async () => {
  const response = await axios.get<IFileStatusList[]>(
    urlBuilder('Lookup/GetFileStatusList')
  );

  return response.data;
};

export const getProjectStatusList = async () => {
  const response = await axios.get<IProjectStatusList[]>(
    urlBuilder('Lookup/GetProjectStatusList')
  );

  return response.data;
};

export const getProgramStatusList = async () => {
  const response = await axios.get<IProgramStatusList[]>(
    urlBuilder('Lookup/GetProgramStatusList')
  );

  return response.data;
};

export const getActivityTypeList = async () => {
  const response = await axios.get<IActivityTypeList[]>(
    urlBuilder('Lookup/GetActivityTypeList')
  );

  return response.data;
};

export const getDocumentCategories = async () => {
  const response = await axios.get<IDocumentCategories[]>(
    urlBuilder('Lookup/GetDocumentCategories')
  );

  return response.data;
};

export const getAssessmentTypeList = async () => {
  const response = await axios.get<IAssessmentTypeList[]>(
    urlBuilder('Lookup/GetAssessmentTypeList')
  );

  return response.data;
};

export const getCountryList = async () => {
  const response = await axios.get<ICountry[]>(
    urlBuilder('Lookup/GetCountryList')
  );

  return response.data;
};

export const getRoles = async () => {
  const response = await axios.get<IRole[]>(urlBuilder('Lookup/GetRoles'));
  return response.data;
};

export const getGridColumnSizeList = async () => {
  const response = await axios.get<IGridColumnSizeList[]>(
    urlBuilder('Lookup/GetGridColumnSizeList')
  );

  return response.data;
};

export const getNotificationFrequencyList = async () => {
  const response = await axios.get<INotificationFrequencyList[]>(
    urlBuilder('Lookup/GetNotificationFrequencyList')
  );

  return response.data;
};

export const getSiteTypeList = async () => {
  const response = await axios.get<ISiteTypeList[]>(
    urlBuilder('Lookup/GetSiteTypeList')
  );
  return response.data;
};

export const getClientTypeList = async () => {
  const response = await axios.get<IClientTypeList[]>(
    urlBuilder('Lookup/GetClientTypeList')
  );
  return response.data;
};
