import axios from 'axios';
import urlBuilder from 'src/utils/urlBuilder';
import { IShare } from 'src/types/IShare';

export const sendShare = async (share: IShare) => {
  return await axios.post<IShare[]>(
    urlBuilder('User/SendSharedNotificationEmail'),
    share
  );
};
