import faker from 'faker';
import { IUploadFile } from 'src/types/IUploadFile';
import { withConstructor } from './mixins/constructor';
import { IWithFaker, withFaker } from './mixins/faker';
import { pipe } from './mixins/pipe';
import { uniqueId } from 'src/utils/number';
import { Levels } from 'src/types/Levels';

const initialState: Partial<IUploadFile> = {
  filename: '',
  fileSize: 0,
  description: '',
  classification: '',
  classificationId: '', // 13
  assessmentType: '',
  assessmentTypeId: '', // 33
  status: '',
  statusId: '', // 1
  active: true,
  internal: false,
  comments: '',
  progress: 0,
  clientId: null,
  programId: null,
  siteId: null,
  projectId: null,
  userId: null,
  linkId: null,
  linkLevel: null,
  clientName: null,
  useFilename: true,
  deserialize: function (systemFile: File) {
    this.filename = systemFile.name;
    this.fileSize = systemFile.size;

    return this as IUploadFile;
  },
  serialize: function () {
    switch (this.linkLevel) {
      case Levels.CLIENT:
        this.linkId = this.clientId;
        break;
      case Levels.PROGRAM:
        this.linkId = this.programId;
        break;
      case Levels.SITE:
        this.linkId = this.siteId;
        break;
      case Levels.PROJECT:
        this.linkId = this.projectId;
        break;
      case Levels.USER:
        this.linkId = this.userId;
        break;
    }

    return {
      active: this.active,
      assessmentTypeID: this.assessmentTypeId,
      assessmentTypeName: this.assessmentType,
      categoryName: this.classification,
      description: this.description,
      documentCategoryID: this.classificationId,
      fileStatusID: this.statusId,
      notes: this.comments,
      clientID: this.clientId,
      programID: this.programId,
      siteID: this.siteId,
      projectID: this.projectId,
      internal: this.internal,
      linkID: this.linkId, // C/P/S/P id
      linkLevel: this.linkLevel, // Level
      id: this.linkId
    };
  },
  isValid: function () {
    return this.validate().length === 0;
  },
  validate: function () {
    let errors = [];

    if (!this.description)
      errors = [...errors, { description: 'Description is required' }];

    if (!this.classificationId)
      errors = [...errors, { classificationId: 'Classification is required' }];

    if (!this.assessmentTypeId)
      errors = [...errors, { assessmentTypeId: 'Assessment Type is required' }];

    if (!this.statusId)
      errors = [...errors, { statusId: 'Status is required' }];

    return errors;
  }
};

const UploadFile = (state = initialState): IUploadFile & IWithFaker => {
  state = {
    id: uniqueId(),
    ...state
  };

  return pipe(
    withFaker({
      filename: faker.system.commonFileName(
        faker.random.arrayElement(['jpg', 'gif', 'jpeg', 'tiff'])
      ),
      fileSize: faker.random.number(1073741824),
      active: faker.random.boolean(),
      internal: faker.random.boolean(),
      progress: faker.random.number(10)
    }),
    withConstructor(UploadFile)
  )({ ...initialState, ...state });
};

export { UploadFile as createUploadFile };
