import React, { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  Badge
} from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import type { Theme } from 'src/theme';
import { useDispatch, useSelector } from 'src/store';
import NotificationPanel from 'src/components/Notifications/NotificationPanel';
import { getCurrentLevelFromPath } from 'src/utils/navigation';
import { Close as CloseIcon } from '@material-ui/icons';
import { Levels } from 'src/types/Levels';
import { capitalize } from 'src/utils/string';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { currentActivities: recentActivities } = useSelector(
    (state) => state.activities
  );
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    const level = getCurrentLevelFromPath(location.pathname);

    setIsVisible(false);

    if (
      level === Levels.CLIENT ||
      level === Levels.PROGRAM ||
      level === Levels.PROJECT ||
      level === Levels.SITE ||
      level === Levels.DOCUMENT ||
      level === 'dashboard'
    ) {
      setIsVisible(true);
    }
  }, [location.pathname]);

  const level = capitalize(getCurrentLevelFromPath(location.pathname)) || '';
  const title = `Recent Activities`;

  return (
    <>
      {isVisible && (
        <Tooltip title={title}>
          <IconButton color="inherit" ref={ref} onClick={handleOpen}>
            <Badge badgeContent={null} color="primary">
              <SvgIcon>
                <HistoryIcon />
              </SvgIcon>
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2} display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ width: '100%' }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {recentActivities.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no recent activities for this section
            </Typography>
          </Box>
        ) : (
          <NotificationPanel recentActivities={recentActivities} />
        )}
      </Popover>
    </>
  );
};

export default Notifications;
