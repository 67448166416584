import React, { ReactNode, useEffect, useRef } from 'react';
import type { FC } from 'react';
import * as api from 'src/apis/user';
import { useState } from 'react';
import LoadingScreen from './LoadingScreen';

interface TermsProps {
  [key: string]: any;
}

const Terms: FC<TermsProps> = ({ getLoadingStatus }) => {
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState('');

  const loadTerms = async () => {
    const response = await api.getTermsAndConditionsFile();
    setIsLoading(false);
    setContent(response.data);
  };

  useEffect(() => {
    loadTerms();
  }, []);

  useEffect(() => {
    if (getLoadingStatus) {
      getLoadingStatus(isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!ref.current) return;

    const frame = ref.current;
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
    frame.style.width = '100%';
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  }, [ref, content]);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <iframe src="about:blank" scrolling="no" frameBorder="0" ref={ref} />
      )}
    </div>
  );
};

export default Terms;
