// @ts-ignore
import React, { useEffect, useCallback } from 'react';
import type { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useLocation, matchPath } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import {
  Dashboard,
  Flag,
  Language,
  People,
  WebAsset,
  Folder,
  Notifications
} from '@material-ui/icons';
import { setFile } from 'src/slices/document';
import { useDispatch } from 'src/store';
import Can from 'src/components/Security/Can';
import { Roles } from 'src/types/IRole';
import useAuth from 'src/hooks/useAuth';
import { IUser } from 'src/types/IUser';
import useSettings from 'src/hooks/useSettings';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  openDesktop: boolean;
  onDesktopNavToggle: () => void;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  exact?: boolean;
  access?: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const getSections = (user: IUser): Section[] => {
  return [
    {
      subheader: '',
      items: [
        // {
        //   title: 'Home',
        //   icon: HomeOutlined,
        //   href: '/app/home'
        // },
        {
          access: 'documents',
          title: 'File Browser',
          icon: Folder,
          href: '/app/files'
        },
        {
          access: 'clients:browse',
          title: 'Clients',
          icon: Language,
          href: '/app/clients/browse'
        },
        {
          access: 'programs:browse',
          title: 'Programs',
          icon: WebAsset,
          href: '/app/programs/browse'
        },
        {
          access: 'sites:browse',
          title: 'Sites',
          icon: Flag,
          href: '/app/sites/browse'
        },
        {
          access: 'projects:browse',
          title: 'Projects',
          icon: Dashboard,
          href: '/app/projects/browse'
        },
        {
          access: 'users:browse',
          title: 'Users',
          icon: People,
          href: '/app/users/browse'
        },
        {
          access: 'notifications',
          title: 'Notifications',
          icon: Notifications,
          href: `/app/users/edit/${user?.userID}/notifications`
        }
      ]
    }
  ];
};

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <Can
        key={key}
        perform={item.access}
        yes={() => (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            open={Boolean(open)}
            title={item.title}
            exact={item.exact || false}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items
            })}
          </NavItem>
        )}
      />
    );
  } else {
    acc.push(
      <Can
        key={key}
        perform={item.access}
        yes={() => (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            exact={item.exact || true}
            title={item.title}
          />
        )}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  fileUploadIcon: {
    height: 60,
    width: 60
  },
  fileUploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #808080',
    borderRadius: '3px'
  },
  drawerOpen: {
    width: 256,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose,
  openMobile,
  openDesktop,
  onDesktopNavToggle
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { settings } = useSettings();

  const sections = getSections(user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const onDrop = useCallback((acceptedFiles) => {
    dispatch(setFile(acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const OpenDesktopContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp> */}
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to="/app/home">
            <Logo />
          </RouterLink>
        </Box>
        <Divider />
        {!user?.simplifiedView && (
          <Box p={2}>
            {sections.map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}

            <Can
              perform={'files:quick_upload'}
              yes={() => (
                <List
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      QUICK UPLOAD
                    </ListSubheader>
                  }
                >
                  <Box
                    className={classes.fileUploadContainer}
                    p={6}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <CloudUpload
                        className={classes.fileUploadIcon}
                        color="primary"
                      />
                    ) : (
                      <CloudUpload className={classes.fileUploadIcon} />
                    )}
                  </Box>
                  <Box mt={1}>
                    <Typography variant="caption">
                      Drag a file onto this space to upload it.
                    </Typography>
                  </Box>
                </List>
              )}
            />
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );

  const ClosedDesktopContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp> */}
        <Box p={2} display="flex" justifyContent="center">
          <Button
            fullWidth
            size="large"
            type="submit"
            component={RouterLink}
            to={'/app/home'}
          >
            <Logo width="100%" />
          </Button>
        </Box>
        <Divider />

        {!user?.simplifiedView && (
          <Box p={0} textAlign="center">
            {sections.map((section) =>
              section.items.map(
                ({
                  icon: Icon,
                  href,
                  title,
                  access
                }: {
                  icon?: any;
                  title?: string;
                  href?: string;
                  access?: string;
                }) => {
                  return (
                    <Can
                      key={title}
                      perform={access}
                      yes={() => (
                        <Tooltip title={title} placement="right">
                          <IconButton
                            activeClassName={classes.active}
                            component={RouterLink}
                            to={href}
                            onClick={(e) => {
                              // e.preventDefault();
                              //onDesktopNavToggle();
                            }}
                          >
                            {Icon && <Icon />}
                          </IconButton>
                        </Tooltip>
                      )}
                    />
                  );
                }
              )
            )}
            <Can
              perform={'files:quick_upload'}
              yes={() => (
                <Box {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Tooltip title="Quick Upload" placement="right">
                    <IconButton onClick={(e) => {}}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {OpenDesktopContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="permanent"
          className={clsx({
            [classes.drawerOpen]: openDesktop,
            [classes.drawerClose]: !openDesktop
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: openDesktop,
              [classes.drawerClose]: !openDesktop
            })
          }}
        >
          {openDesktop && OpenDesktopContent}
          {!openDesktop && ClosedDesktopContent}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
