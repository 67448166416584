import React from 'react';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import type { Theme } from 'src/theme';
import type { FC } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    borderRadius: 0
  }
}));

interface Props {
  [key: string]: any;
  fileName?: string;
  progress?: number;
}

const Downloader: FC<Props> = ({ fileName, progress = 0 }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography gutterBottom>
        Downloading {fileName} ({progress}%)
      </Typography>
      <Box width="100%">
        <ProgressBar progress={progress} />
      </Box>
    </Paper>
  );
};

export default Downloader;
