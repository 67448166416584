import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import type IAction from 'src/types/IAction';
import { IUser } from '../@types/IUser';
import type { IUserParams } from './controller';
import type { IState } from './state';

export enum ACTION_TYPES {
  INITIALIZE = 'INITIALIZE',
  LOADING = 'LOADING',
  SET_STATE = 'SET_STATE',
  CANCEL_TOKEN_SOURCE = 'CANCEL_TOKEN_SOURCE',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_USERS = 'SET_USERS',
  DELETE_USER = 'DELETE_USER',
  SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
}

export const ACTIONS = {
  loading: (): IAction => ({ type: ACTION_TYPES.LOADING, payload: true }),
  setState: (state: IState): IAction => ({
    type: ACTION_TYPES.SET_STATE,
    payload: state
  }),
  initialize: (state: IState): IAction => ({
    type: ACTION_TYPES.INITIALIZE,
    payload: state
  }),
  setCancelToken: (cancelToken: CancelTokenSource): IAction => ({
    type: ACTION_TYPES.CANCEL_TOKEN_SOURCE,
    payload: cancelToken
  }),
  setPagination: (pagination: IPager): IAction => ({
    type: ACTION_TYPES.SET_PAGINATION,
    payload: pagination
  }),
  setUsers: (users: IUser[] = [], totalRecords: number = 0): IAction => ({
    type: ACTION_TYPES.SET_USERS,
    payload: {
      users,
      totalRecords
    }
  }),
  deleteUser: (user: IUser): IAction => ({
    type: ACTION_TYPES.DELETE_USER,
    payload: user
  }),

  setSearchParams: (params: IUserParams): IAction => ({
    type: ACTION_TYPES.SET_SEARCH_PARAMS,
    payload: params
  })
};
