import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import imageEncode from 'src/utils/imageEncode';
import { ISummary } from 'src/types/ISummary';
import * as api from 'src/apis/user';
import User from 'src/models/User';
import UserAccess from 'src/models/UserAccess';

interface UsersState {
  register: User;
  newUser: User;

  user: User;
  currentUser: User;

  users: User[];
  currentUsers: User[] | UserAccess[];
  isLoading: boolean;

  currentUserSummary: ISummary;
  isLoadingSummary: boolean;

  gridColumns: { [key: string]: string[] };

  response: any;
  request: any;

  accessResponse: any;
  accessRequest: {
    baseQuery: any;
    id: number;
  };
  isAccessLoading: boolean;
}

const initialState: UsersState = {
  register: null,
  newUser: null,

  user: null,
  currentUser: new User(),

  users: [],
  currentUsers: [],
  isLoading: false,

  currentUserSummary: null,
  isLoadingSummary: false,

  gridColumns: {},

  // redux stuff
  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {},

  accessResponse: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  accessRequest: { baseQuery: {}, id: 0 },
  isAccessLoading: false
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setRequest(
      state: UsersState,
      action: PayloadAction<{
        request: any;
      }>
    ) {
      const { request } = action.payload;
      state.request = request;
      state.isLoading = false;
    },

    setAccessResponse(state: UsersState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.accessResponse = response;
      state.isAccessLoading = false;
    },

    setAccessRequest(
      state: UsersState,
      action: PayloadAction<{ request: any }>
    ) {
      const { request } = action.payload;
      state.accessRequest = request;
      state.isAccessLoading = false;
    },

    setResponse(state: UsersState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.response = response;
      state.isLoading = false;
    },

    setMyAccountInfo(
      state: UsersState,
      action: PayloadAction<{ users: User[] }>
    ) {
      const { users } = action.payload;
    },

    setUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.user = user;
      state.isLoading = false;
    },

    setUsers(state: UsersState, action: PayloadAction<{ users: User[] }>) {
      const { users } = action.payload;
      state.users = users;
      state.isLoading = false;
    },

    setCurrentUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.currentUser = user;
    },

    setCurrentUsers(
      state: UsersState,
      action: PayloadAction<{ users: User[] | UserAccess[] }>
    ) {
      const { users } = action.payload;
      state.currentUsers = users;
      state.isLoading = false;
    },

    getUserByUserId(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.currentUser = user;
    },

    setCurrentUserSummary(
      state: UsersState,
      action: PayloadAction<{ summary: ISummary }>
    ) {
      const { summary } = action.payload;
      state.currentUserSummary = summary;
      state.isLoadingSummary = false;
    },

    createUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.users = [...state.users, user];
      state.newUser = user;
    },

    updateMyUserAccount(
      state: UsersState,
      action: PayloadAction<{ user: User }>
    ) {
      const { user } = action.payload;
      state.currentUser = user;
    },

    updateUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.users = state.users.map((x) =>
        x.userID === user.userID ? user : x
      );
      state.currentUser = user;
    },

    deleteUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.users = state.users.filter((x) => x.userID !== user.userID);
    },

    isLoading(state: UsersState, action: PayloadAction) {
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isLoading = true;
    },

    isAccessLoading(state: UsersState, action: PayloadAction) {
      state.accessResponse = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isAccessLoading = true;
    },
    isLoadingSummary(state: UsersState, action: PayloadAction) {
      state.isLoadingSummary = true;
    },
    updateGridColumns(
      state: UsersState,
      action: PayloadAction<{ gridCode: string; columns: string[] }>
    ) {
      const { gridCode, columns } = action.payload;

      state.gridColumns = {
        ...state.gridColumns,
        [gridCode]: columns
      };
    },
    reset(state: UsersState, action: PayloadAction<{}>) {
      state.currentUser = null;
      state.currentUserSummary = null;
    }
  }
});

export const reducer = slice.reducer;

export const getUsersByQueryRaw = (baseQuery): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isAccessLoading());
  const response = await api.getUsersByQueryRaw(baseQuery);
  dispatch(slice.actions.setAccessResponse({ response }));
  dispatch(
    slice.actions.setRequest({
      request: baseQuery
    })
  );
};

export const getUserAccessByClientId = (
  baseQuery,
  clientId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getUserAccessByClientId(baseQuery, clientId);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(
    slice.actions.setAccessRequest({
      request: {
        baseQuery,
        id: clientId
      }
    })
  );
};

export const getUserAccessByProgramId = (
  baseQuery,
  programId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getUserAccessByProgramId(baseQuery, programId);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(
    slice.actions.setAccessRequest({
      request: {
        baseQuery,
        id: programId
      }
    })
  );
};

export const getUserAccessBySiteId = (baseQuery, siteId): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getUserAccessBySiteId(baseQuery, siteId);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(
    slice.actions.setAccessRequest({
      request: {
        baseQuery,
        id: siteId
      }
    })
  );
};

export const getUserAccessByProjectId = (
  baseQuery,
  projectId: number
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getUserAccessByProjectId(baseQuery, projectId);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(
    slice.actions.setAccessRequest({
      request: {
        baseQuery,
        id: projectId
      }
    })
  );
};

// old

export const getUser = (userId: number): AppThunk => async (dispatch) => {
  const user = await api.getUserByUserId(userId);
  await user.loadAvatar();

  dispatch(slice.actions.setUser({ user }));
};

export const getUsersBySearchValue = (value: string): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.setUsers({ users: [] }));
  dispatch(slice.actions.isLoading());
  const users = await api.getUsersBySearchValue(value);
  dispatch(slice.actions.setUsers({ users }));
};

export const getUsers = (): AppThunk => async (dispatch) => {
  const users = await api.getUsers();
  dispatch(slice.actions.setUsers({ users }));
};

export const getUserByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(
    slice.actions.setCurrentUser({
      user: new User()
    })
  );
  const user = await api.getUserByUserId(userId);
  await user.loadAvatar();
  // const avatar = await api.getUserImageByUserId(userId);
  // user.avatar = avatar?.data ? imageEncode(avatar.data) : '';
  dispatch(
    slice.actions.setCurrentUser({
      user
    })
  );
};

export const getUserAccessByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const user = await api.getUserAccessByUserId(userId);
  dispatch(slice.actions.setCurrentUser({ user }));
};

// export const getUserAccessByClientId = (clientId: number): AppThunk => async (
//   dispatch
// ) => {
//   dispatch(slice.actions.isLoading());
//   const users = await api.getUserAccessByClientId(clientId);
//   dispatch(slice.actions.setCurrentUsers({ users }));
// };

// export const getUserAccessByProgramId = (programId: number): AppThunk => async (
//   dispatch
// ) => {
//   dispatch(slice.actions.isLoading());
//   const users = await api.getUserAccessByProgramId(programId);
//   dispatch(slice.actions.setCurrentUsers({ users }));
// };

// export const getUserAccessBySiteId = (siteId: number): AppThunk => async (
//   dispatch
// ) => {
//   dispatch(slice.actions.isLoading());
//   const users = await api.getUserAccessBySiteId(siteId);
//   dispatch(slice.actions.setCurrentUsers({ users }));
// };

// export const getUserAccessByProjectId = (projectId: number): AppThunk => async (
//   dispatch
// ) => {
//   dispatch(slice.actions.isLoading());
//   const users = await api.getUserAccessByProjectId(projectId);
//   dispatch(slice.actions.setCurrentUsers({ users }));
// };

export const getUserSummaryByUserId = (userId: number): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoadingSummary());
  const summary = await api.getUserSummaryByUserId(userId);
  dispatch(slice.actions.setCurrentUserSummary({ summary }));
};

export const createUser = (user: User): AppThunk => async (dispatch) => {
  const users = await api.createUser(user.serialize());
  dispatch(slice.actions.createUser({ user }));
};

export const updateUser = (user: User): AppThunk => async (dispatch) => {
  const updatedUser = await api.updateUser(user.serialize());
  dispatch(
    slice.actions.updateUser({
      user: new User({
        ...user,
        ...updatedUser
      })
    })
  );
};

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.reset({}));
};

export const deleteUser = (user: User): AppThunk => async (dispatch) => {
  await api.deleteUserByUserId(user.userID);
  dispatch(slice.actions.deleteUser({ user }));
};

// MY ACCOUNT INFO
export const updateMyUserAccount = (user: User): AppThunk => async (
  dispatch
) => {
  const updatedUser = await api.updateMyAccountInfo(user);
  dispatch(
    slice.actions.updateMyUserAccount({
      user
    })
  );
};

export default slice;
