import { ISite } from 'src/types/ISite';
import faker from 'faker';
import { format } from 'src/utils/date';
import { removeEmpty, serializeObj } from 'src/utils/object';
import { getClientImageByClientId } from 'src/apis/client';
import imageEncode from 'src/utils/imageEncode';

export default class Site implements ISite {
  active?: boolean;
  confidential?: boolean;
  siteID: number; // *
  siteName: string; // required
  siteCode: string; // required
  clientName: string; // required
  clientID: number; // required
  contactName?: string;
  contactEmail?: string;
  phone?: string;
  address?: string;
  address1?: string;
  fullAddress?: string = '';
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  gps?: string;
  siteTypeID?: number;
  siteSF?: number;
  quireID?: string;
  visionID?: string;
  assetCalcID?: string;
  proTrackID?: string;
  notes?: string;
  referenceNumber?: string; // don't need
  managerID?: number; // don't need
  createdDate?: string;
  modifiedDate?: string;
  avatar?: string;

  constructor(init?: Partial<ISite>) {
    Object.assign(this, init);
  }

  get completeAddress(): string {
    const fullAddress =
      this.fullAddress !== ''
        ? this.fullAddress
        : `${this.address} ${this.address1}`;
    return `${fullAddress.trim()}, ${this.city} ${this.state}, ${
      this.country
    } ${this.zipCode} `;
  }

  generateMockData() {
    this.siteName = faker.commerce.productName();
    this.siteCode = faker.random.number().toString();
    this.address = '101 Allegra';
    this.city = 'Wasaga Beach';
    this.zipCode = 'L9Y 2H4';
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  async loadAvatar() {
    let response = null;

    try {
      response = await getClientImageByClientId(this.clientID);
    } catch (e) {}

    this.avatar = response?.data ? imageEncode(response.data) : '';
  }

  public static deserialize(data: ISite) {
    let site = new Site();

    for (let key in data) {
      site[key] = data[key];
    }

    site.createdDate = format(data.createdDate);
    site.modifiedDate = format(data.modifiedDate);

    return site;
  }
}
