import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { IDocument } from 'src/types/IDocument';
import * as api from 'src/apis/document';
import { uploadSiteInfoFile } from 'src/apis/site';
import { uploadProgramInfoFile } from 'src/apis/program';
import { uploadUserInfoFile } from 'src/apis/user';
interface DocumentsState {
  newFile: IDocument;
  documents: IDocument[];
  currentDocument: IDocument;
  currentDocuments: IDocument[];
  isLoading: boolean;
  selection: any[];
  isModalOpen: boolean;
  selectedFiles: any;
  isServerPreparingFile: boolean;
  progress: number;
  downloadProgress: number;
  message: string;
  level: string;
  id: string;
  response: any;
  request: {
    baseQuery: any;
  };
}

export let currentFile: File = null;
export let currentFiles: File[] = null;

const initialState: DocumentsState = {
  newFile: null,
  documents: [],
  currentDocument: null,
  currentDocuments: [],
  isLoading: false,
  selection: [],
  isModalOpen: false,
  selectedFiles: null,
  isServerPreparingFile: false,
  progress: 0,
  downloadProgress: 0,
  message: '',
  level: null,
  id: null,
  response: {
    data: {
      result: [],
      pageNumber: 0,
      totalPages: 0,
      totalRecords: 0
    }
  },
  request: {
    baseQuery: {}
  }
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setRequest(
      state: DocumentsState,
      action: PayloadAction<{
        request: any;
      }>
    ) {
      const { request } = action.payload;
      state.request = request;
      state.isLoading = false;
    },

    setResponse(state: DocumentsState, action: PayloadAction<{ response }>) {
      const { response } = action.payload;
      state.response = response;
      state.isLoading = false;
    },

    setFile(
      state: DocumentsState,
      action: PayloadAction<{ files: File[]; options? }>
    ) {
      const { files, options } = action.payload;
      const file = files[0];

      currentFile = file as File;
      state.currentDocument = null;
      state.isModalOpen = true;
      state.level = options?.level;
      state.id = options?.id;

      // state.prediction = getPrediction(file, location);
    },
    setFiles(
      state: DocumentsState,
      action: PayloadAction<{ files: File[]; options? }>
    ) {
      const { files, options } = action.payload;

      currentFiles = files;
      state.currentDocument = null;
      state.isModalOpen = true;
      state.level = options?.level;
      state.id = options?.id;

      // state.prediction = getPrediction(file, location);
    },
    setDocuments(
      state: DocumentsState,
      action: PayloadAction<{ documents: IDocument[] }>
    ) {
      const { documents } = action.payload;
      state.documents = documents;
      state.isLoading = false;
    },
    setCurrentDocuments(
      state: DocumentsState,
      action: PayloadAction<{ documents: IDocument[] }>
    ) {
      const { documents } = action.payload;
      state.currentDocuments = documents;
      state.isLoading = false;
    },
    setDownloadProgress(
      state: DocumentsState,
      action: PayloadAction<{ downloadProgress: number }>
    ) {
      const { downloadProgress } = action.payload;
      state.downloadProgress = downloadProgress;
    },

    addDocument(
      state: DocumentsState,
      action: PayloadAction<{ document: IDocument }>
    ) {
      const { document } = action.payload;

      state.documents = [document, ...state.documents];
      state.newFile = document;
    },

    updateDocument(
      state: DocumentsState,
      action: PayloadAction<{ document: IDocument }>
    ) {
      const { document } = action.payload;

      state.documents = state.documents.map((x) =>
        x.documentID === document.documentID ? document : x
      );
    },

    deleteDocument(
      state: DocumentsState,
      action: PayloadAction<{ document: IDocument }>
    ) {
      const { document } = action.payload;

      state.documents = state.documents.filter(
        (x) => x.documentID !== document.documentID
      );

      state.currentDocuments = state.currentDocuments.filter(
        (x) => x.documentID !== document.documentID
      );
    },
    isLoading(state: DocumentsState, action: PayloadAction) {
      state.response = {
        data: {
          result: [],
          pageNumber: 0,
          totalPages: 0,
          totalRecords: 0
        }
      };
      state.isLoading = true;
    },
    isServerPreparingFile(
      state: DocumentsState,
      action: PayloadAction<boolean>
    ) {
      state.isServerPreparingFile = action.payload ?? true;
    },

    downloadSelected(
      state: DocumentsState,
      action: PayloadAction<{ selection: any[] }>
    ) {
      const { selection } = action.payload;
      state.selection = selection;
    },

    setDocumentsSelected(
      state: DocumentsState,
      action: PayloadAction<{ selection: any[] }>
    ) {
      const { selection } = action.payload;
      state.selection = selection;
    },

    openDocument(
      state: DocumentsState,
      action: PayloadAction<{ document: IDocument }>
    ) {
      const { document } = action.payload;
      currentFile = null;
      state.currentDocument = document;
      state.isModalOpen = true;
    },
    closeModal(state: DocumentsState, action: PayloadAction) {
      currentFile = null;
      currentFiles = null;
      state.isModalOpen = false;
      state.level = null;
      state.id = null;
    },

    updateProgress(
      state: DocumentsState,
      action: PayloadAction<{ progress: number }>
    ) {
      const { progress } = action.payload;
      state.progress = progress;
    },
    updateMessage(
      state: DocumentsState,
      action: PayloadAction<{ message: string }>
    ) {
      const { message } = action.payload;
      state.message = message;
    },
    markDocumentAsRead(
      state: DocumentsState,
      action: PayloadAction<{ document: IDocument }>
    ) {
      const { document } = action.payload;
      state.response.data.result = state.response.data.result.map((x) => {
        if (x.documentID === document.documentID) {
          return {
            ...x,
            numNewComments: 0
          };
        } else return x;
      });
    }
  }
});

export const reducer = slice.reducer;

export const getDocumentsByQueryRaw = (baseQuery): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const response = await api.getDocumentsByQueryRaw(baseQuery);
  dispatch(slice.actions.setResponse({ response }));
  dispatch(
    slice.actions.setRequest({
      request: {
        baseQuery
      }
    })
  );
};

export const setFile = (files: File[], options?): AppThunk => async (
  dispatch
) => {
  if (files.length > 1) dispatch(slice.actions.setFiles({ files, options }));
  else dispatch(slice.actions.setFile({ files, options }));
};

export const closeModal = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const uploadFile = (saveTo): AppThunk => async (dispatch) => {
  if (saveTo === 'Sites') upload(uploadSiteInfoFile, dispatch);
  if (saveTo === 'Programs') upload(uploadProgramInfoFile, dispatch);
  if (saveTo === 'Users') upload(uploadUserInfoFile, dispatch);
};

export const getDocuments = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getDocuments();
  dispatch(slice.actions.setDocuments({ documents }));
};

export const getRecentDocuments = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getRecentDocuments();
  dispatch(slice.actions.setDocuments({ documents }));
};

export const getDocumentsByProgramId = (programId): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getDocumentsByProgramId(programId);
  dispatch(
    slice.actions.setCurrentDocuments({
      documents
    })
  );
};

export const getDocumentsBySiteId = (siteId): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getDocumentsBySiteId(siteId);
  dispatch(
    slice.actions.setCurrentDocuments({
      documents
    })
  );
};

export const getDocumentsByProjectId = (projectId): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getDocumentsByProjectId(projectId);
  dispatch(
    slice.actions.setCurrentDocuments({
      documents
    })
  );
};

export const openDocument = (document: IDocument): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.openDocument({ document }));
};

const upload = async (api, dispatch) => {
  try {
    const response = await api(currentFile, (event) => {
      dispatch(
        slice.actions.updateProgress({
          progress: Math.round((100 * event.loaded) / event.total)
        })
      );
    });

    dispatch(slice.actions.updateMessage({ message: response.data.message }));
  } catch (e) {
    dispatch(
      slice.actions.updateMessage({ message: 'Could not upload the file!' })
    );
  }
};

export const getDocumentsByUserId = (userId): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const documents = await api.getDocumentsByUserId(userId);
  dispatch(
    slice.actions.setCurrentDocuments({
      documents
    })
  );
};

export const updateDocument = (
  id: number,
  document: IDocument,
  callback?: any
): AppThunk => async (dispatch) => {
  await api.updateDocument(id, document);

  if (callback) callback();
  dispatch(slice.actions.updateDocument({ document }));
};

export const deleteDocument = (document: IDocument): AppThunk => async (
  dispatch
) => {
  await api.deleteDocument(document);
  dispatch(slice.actions.deleteDocument({ document }));
};

export const addDocument = (
  $document,
  level?,
  id?,
  callback?: any
): AppThunk => async (dispatch) => {
  let document = $document;

  if (level && id) document = await api.addDocument(id, $document);
  if (callback) callback();
  dispatch(slice.actions.addDocument({ document }));
};

export const deleteDocumentFile = (document: IDocument): AppThunk => async (
  dispatch
) => {
  await api.deleteDocumentFile(document);
  dispatch(slice.actions.deleteDocument({ document }));
};

export const setDocumentsSelected = (selection: any[]): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.setDocumentsSelected({ selection }));
};

export const downloadSelectedDocuments = (
  selection?: any[],
  setProgress?
): AppThunk => async (dispatch, getState) => {
  const { selection } = getState().documents;

  if (selection.length === 0) return;

  dispatch(slice.actions.isServerPreparingFile());

  if (selection.length > 1) {
    try {
      const res = await api.downloadDocumentsAsZip(
        selection,
        dispatch,
        setProgress
      );

      if (!res) dispatch(slice.actions.isServerPreparingFile(false));
    } catch (e) {
      dispatch(slice.actions.isServerPreparingFile(false));
    }
  } else {
    const document = await api.getDocumentsByDocumentId(selection[0]);

    if (document) await api.getDocumentFile(document);
    else dispatch(slice.actions.isServerPreparingFile(false));
  }
  dispatch(slice.actions.isServerPreparingFile(false));
};

export const setAllDocumentCommentsToRead = (
  document: IDocument
): AppThunk => async (dispatch) => {
  if (!document) return;

  dispatch(
    slice.actions.markDocumentAsRead({
      document
    })
  );

  await api.setAllDocumentCommentsToRead(document.documentID);

  dispatch(
    slice.actions.updateDocument({
      document: {
        ...document,
        numNewComments: 0
      }
    })
  );
};

export default slice;
