import { IProject } from 'src/types/IProject';
import faker from 'faker';
import { format } from 'src/utils/date';
import { removeEmpty, serializeObj } from 'src/utils/object';
import { getClientImageByClientId } from 'src/apis/client';
import imageEncode from 'src/utils/imageEncode';

export default class Project implements IProject {
  active?: boolean = false;
  projectID?: number;
  projectName: string; // required
  projectCode: string; // required
  clientID: number; //required
  clientName: string;
  programCode: string;
  programID: number; // required
  programName: string;
  siteID: number; // required
  siteName: string;
  siteAddress?: string;
  siteCity?: string;
  siteState?: string;
  siteCountry?: string;
  siteContactName?: string;
  siteContactEmail?: string;
  siteContactPhone?: string;
  programManagerID?: number;
  programManager?: string;
  serviceNumber?: number;
  actualDeliveryDate?: string;
  dtpDate?: string;
  contractedDTC: string;
  serviceDescription?: string;
  protocol?: string;
  issueDescription?: string;
  issueFlag?: boolean = false;
  createdDate: string;
  createdBy: number;
  modifiedDate: string;
  modifiedBy: number;
  notes?: string;
  confidential: boolean = false;
  quireID?: string;
  visionID?: string;
  managerID?: number;
  assetCalcID?: any;
  proTrackID?: any;
  referenceNumber?: string;
  managerName?: string;
  managerEmail?: string;
  state?: string;
  createdByName?: string;
  modifiedByName?: string;

  projectStatusID?: number;
  externalProjectStatus?: string;
  internalProjectStatus?: string;

  bvProjectNumber?: string;

  onHold?: boolean = false;

  avatar?: string;

  constructor(init?: Partial<IProject>) {
    Object.assign(this, init);
  }

  serialize(): IProject {
    return removeEmpty(serializeObj(this));
  }

  async loadAvatar() {
    let response = null;

    try {
      response = await getClientImageByClientId(this.clientID);
    } catch (e) {}

    this.avatar = response?.data ? imageEncode(response.data) : '';
  }

  generateMockData() {
    //required fields
    this.projectName = faker.commerce.productName();
    this.projectCode = String(faker.random.number());
    this.confidential = true;
    this.onHold = Math.floor(Math.random() * 12) > 6;
    this.notes = faker.lorem.paragraph();
  }

  public static deserialize(data: IProject) {
    let project = new Project();

    for (let key in data) {
      project[key] = data[key];
    }

    project.createdDate = format(data.createdDate);
    project.modifiedDate = format(data.modifiedDate);

    return project;
  }
}
