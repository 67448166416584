import axios from 'axios';
import { ISite } from 'src/types/ISite';
import Site from 'src/models/Site';
import { ISummary } from 'src/types/ISummary';
import urlBuilder from 'src/utils/urlBuilder';
import { queryHelper } from './dynamic-query-helper';
interface ISiteQuery {
  pageNumber: number;
  result: ISite[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const getSitesByQueryRaw = async (query: any) => {
  return await axios.post<ISiteQuery>(
    urlBuilder('Client/GetSitesByDynamicQuery'),
    query
  );
};

export const getSitesByDynamicQuery = async (query: any, limit?: number) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1
    };
  }
  const response = await axios.post<ISiteQuery>(
    urlBuilder('Client/GetSitesByDynamicQuery'),
    query
  );

  let sites: Site[] = response.data.result.map((x) => Site.deserialize(x));

  return sites;
};

export const getSites = async () => {
  const sites = await getSitesByDynamicQuery({});
  return sites;
};

export const getSiteBySiteId = async (siteId: number) => {
  const sites = await getSitesByDynamicQuery({
    where: queryHelper.getSite(siteId).getQuery()
  });

  return sites[0] || null;
};

export const getSitesByClientId = async (clientId: number) => {
  const sites = await getSitesByDynamicQuery({
    where: queryHelper.getClient(clientId).getQuery()
  });

  return sites;
};

export const getSitesByProgramId = async (query, programId: number) => {
  return await axios.post<ISiteQuery[]>(
    urlBuilder('Client/GetSitesByProgramId'),
    query,
    {
      params: {
        programId
      }
    }
  );
};

export const getSitesByProjectId = async (query, projectId: number) => {
  return await axios.post<ISiteQuery[]>(
    urlBuilder('Client/GetSitesByProjectId'),
    query,
    {
      params: {
        projectId
      }
    }
  );
};

export const getSitesBySearchValue = async (value: string, extraQuery?) => {
  let query = [];

  if (extraQuery) {
    query = extraQuery;
  }

  query = [
    ...query,
    {
      andOr: query.length === 0 ? '' : 'and',
      items: [
        {
          andOr: '',
          field: 'siteName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'siteId',
          operator: 'contains',
          value
        }
      ]
    }
  ];

  const sites = await getSitesByDynamicQuery({
    where: query,
    orderBy: [
      {
        fieldName: 'createdDate',
        orderType: 'desc'
      }
    ]
  });

  return sites;
};

export const getSitesByUserId = async (userId: number) => {
  const sites = await getSitesByDynamicQuery({
    pagination: true,
    pageNumber: 1,
    userId
  });

  return sites;
};

export const getSiteSummaryBySiteId = async (siteId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetSiteSummaryBySiteId'),
    {
      params: {
        siteId
      }
    }
  );

  return response.data;
};

export const createSite = async (site: ISite) => {
  const response = await axios.post<ISite>(urlBuilder('Client/AddSite'), site);

  return response.data;
};

export const deleteSiteBySiteId = async (siteId: number) => {
  await axios.post(urlBuilder('Client/DeleteSite'), null, {
    params: {
      id: siteId
    }
  });
};

export const updateSite = async (site: ISite) => {
  const response = await axios.post<ISite>(
    urlBuilder('Client/UpdateSite'),
    site,
    {
      params: {
        id: site.siteID
      }
    }
  );

  return response.data;
};

export const uploadSiteInfoFile = async (file: File, onUploadProgress) => {
  return axios.post(
    urlBuilder('Client/UploadSiteInfoFile'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress
    }
  );
};
