import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import { IUser } from '../@types/IUser';
import type { IUserParams } from './controller';

export interface IState {
  isDirty?: boolean;
  isLoading?: boolean;
  isInitialized?: boolean;
  apiCancelToken?: CancelTokenSource;
  users?: IUser[];
  params?: IUserParams;
  pagination?: IPager;
  totalRecords?: number;
}

export const defaultState: IState = {
  isDirty: false,
  isLoading: false,
  isInitialized: false,
  users: [],
  params: {
    activeRecord: true,
    searchValue: '',
    visibleColumns: [],
    filters: [],
    sorting: []
  },
  pagination: {
    offset: 0,
    limit: 25
  },
  totalRecords: 0
};
