import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import FileManager from 'src/views/modals/FileManager/FileManager';
import ShareManager from 'src/views/modals/ShareManager/ShareManager';
import BatchCSVManger from 'src/views/modals/BatchCSVManager/BatchCSVManger';
import UpdatedTerms from 'src/components/UpdatedTerms';
import AppLoader from 'src/components/AppLoader';
import { useDispatch } from 'src/store';
import { getGridColumnSizeList } from 'src/slices/lookup';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [isDesktopNavOpen, setDesktopNavToggle] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGridColumnSizeList());
  }, [dispatch, getGridColumnSizeList]);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onDesktopNavToggle={() => setDesktopNavToggle((prev) => !prev)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openDesktop={isDesktopNavOpen}
        onDesktopNavToggle={() => setDesktopNavToggle((prev) => !prev)}
      />
      <BatchCSVManger />
      <FileManager />
      <ShareManager />
      <UpdatedTerms />
      <AppLoader />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
