import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import { IProject } from '../@types/IProject';
import type { IProjectParams } from './controller';

export interface IState {
  isDirty?: boolean;
  isLoading?: boolean;
  isInitialized?: boolean;
  apiCancelToken?: CancelTokenSource;
  projects?: IProject[];
  params?: IProjectParams;
  pagination?: IPager;
  totalRecords?: number;
}

export const defaultState: IState = {
  isDirty: false,
  isLoading: false,
  isInitialized: false,
  projects: [],
  params: {
    activeRecord: true,
    searchValue: '',
    visibleColumns: [],
    filters: [],
    sorting: [],
    showConfidentialOnly: false,
    showRegularOnly: false
  },
  pagination: {
    offset: 0,
    limit: 25
  },
  totalRecords: 0
};
