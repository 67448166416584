import axios from 'axios';
import { IProject } from 'src/types/IProject';
import Project from 'src/models/Project';
import { ISummary } from 'src/types/ISummary';
import urlBuilder from 'src/utils/urlBuilder';
import { queryHelper } from './dynamic-query-helper';

interface IProjectQuery {
  pageNumber: number;
  result: IProject[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const getProjectsByQueryRaw = async (query: any, limit?: number) => {
  return await axios.post<IProjectQuery>(
    urlBuilder('Client/GetProjectsByDynamicQuery'),
    query
  );
};

export const getProjectsByDynamicQuery = async (query: any, limit?: number) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1
    };
  }

  const response = await axios.post<IProjectQuery>(
    urlBuilder('Client/GetProjectsByDynamicQuery'),
    query
  );

  let projects: Project[] = response.data.result.map((x) =>
    Project.deserialize(x)
  );

  return projects;
};

export const getProjects = async () => {
  const projects = await getProjectsByDynamicQuery({});
  return projects;
};

export const getProjectByProjectId = async (projectId: number) => {
  const projects = await getProjectsByDynamicQuery({
    where: queryHelper.getProject(projectId).getQuery()
  });

  return projects[0] || null;
};

export const getProjectsByClientId = async (clientId: number) => {
  const projects = await getProjectsByDynamicQuery({
    where: queryHelper.getClient(clientId).getQuery()
  });

  return projects;
};

export const getProjectsBySiteId = async (query) => {
  return await getProjectsByQueryRaw(query);
};

export const getProjectsByProgramId = async (query, programId: number) => {
  const projects = await getProjectsByQueryRaw({
    where: queryHelper.getProgram(programId, query).getQuery()
  });

  return projects;
};

export const getRecentProjectByUserId = async (userId: number) => {
  const projects = await getProjectsByDynamicQuery({
    userId,
    pagination: true,
    rowsPerPage: 1,
    pageNumber: 1,
    orderBy: [{ fieldName: 'modifiedDate', OrderType: 'desc' }],
    where: [
      {
        andOr: '',
        items: [
          {
            andOr: '',
            field: 'active',
            operator: '=',
            value: `true`
          }
        ]
      }
    ]
  });

  return projects[0] || null;
};

export const getProjectSummaryByProjectId = async (projectId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetProjectSummaryByProjectId'),
    {
      params: {
        projectId
      }
    }
  );
  return response.data;
};

export const getProjectStatusSummaryByProgramId = async (programId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetProjectStatusSummaryByProgramId'),
    {
      params: {
        programId
      }
    }
  );

  return response.data;
};

export const createProject = async (projects: IProject) => {
  const response = await axios.post<IProject>(
    urlBuilder('Client/AddProject'),
    projects
  );

  return response.data;
};

export const deleteProjectByProjectId = async (projectId: number) => {
  await axios.post(urlBuilder('Client/DeleteProject'), null, {
    params: {
      id: projectId
    }
  });
};

export const updateProject = async (project: IProject) => {
  const response = await axios.post<IProject>(
    urlBuilder('Client/UpdateProject'),
    project,
    {
      params: {
        id: project.projectID
      }
    }
  );

  return response.data;
};

export const uploadProjectInfoFile = async (file: File, onUploadProgress) => {
  return axios.post(
    urlBuilder('Client/UploadProjectInfoFile'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'text/csv'
      },
      onUploadProgress
    }
  );
};

export const getProjectsBySearchValue = async (value: string, extraQuery?) => {
  let query = [];

  if (extraQuery) {
    query = extraQuery;
  }

  query = [
    ...query,
    {
      andOr: query.length === 0 ? '' : 'and',
      items: [
        {
          andOr: '',
          field: 'projectName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'projectID',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'bvProjectNumber',
          operator: 'contains',
          value
        }
      ]
    }
  ];

  const projects = await getProjectsByDynamicQuery({
    where: query,
    orderBy: [
      {
        fieldName: 'createdDate',
        orderType: 'desc'
      }
    ]
  });

  return projects;
};
