import React, { useState } from 'react';
import type { FC } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Box,
  Button,
  Grid,
  Divider,
  TextField,
  makeStyles,
  Card,
  CardHeader,
  IconButton,
  Typography,
  Tooltip
} from '@material-ui/core';
import { IUser } from 'src/types/IUser';
import ShareListItem from './ShareListItem';
import { Close, Warning } from '@material-ui/icons';
import { pluralize } from 'src/utils/string';
import UserAutoComplete from 'src/components/Common/Forms/UserAutoComplete';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import { IShareUser } from './@types/ISharedUser';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    '&> * + *': {
      marginLeft: 10
    }
  },
  userItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 10,
    color: theme.palette.colors.caution
  }
}));

export interface Props {
  [key: string]: any;
  onAddUser?: (e: any, user: IUser) => void;
  onCancel?: () => void;
  onClose?: () => void;
  onSendInvite?: (e: any, user: IUser[]) => void;
  onRemove?: (e: any, user: IUser) => void;
  onSubmit?: any;
  selectedUsers?: IShareUser[];
  shareObject?: any;
  shareType?: string;
}

const noop = () => {};

const ShareForm: FC<Props> = ({
  onSubmit = noop,
  selectedUsers = [],
  onAddUser = noop,
  onCancel = noop,
  onSendInvite = noop,
  onClose = noop,
  shareObject,
  shareType,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, setRefresh] = useState(Math.random() * 99999);

  const generateTitle = (shareType, shareObject) => {
    let title = 'Share';

    if (shareType == 'Client')
      title = `Share ${shareType}: ${shareObject?.clientName}`;

    if (shareType == 'Site')
      title = `Share ${shareType}: ${shareObject?.siteName}`;

    if (shareType == 'Program')
      title = `Share ${shareType}: ${shareObject?.programName}`;

    if (shareType == 'Project')
      title = `Share ${shareType}: ${shareObject?.projectName}`;

    if (shareType == 'Document') title = `Share ${shareType}`;

    if (shareType == 'Documents') title = `Share ${shareType}`;

    return title;
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      initialValues={{
        submit: null
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={generateTitle(shareType, shareObject)}
                action={
                  onClose && (
                    <IconButton onClick={onClose}>
                      <Close />
                    </IconButton>
                  )
                }
              />

              <Divider />

              <Box m={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <UserAutoComplete
                        key={refresh}
                        required
                        extraQuery={queryHelper
                          .getActiveRecord()
                          .getEmailConfirmed()
                          .getQuery()}
                        onBlur={handleBlur}
                        onChange={(e: object, value: any | null) => {
                          setSelectedUser(value);
                        }}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        label="Type to search for a user"
                      />

                      <Box flexGrow={1} mr={2} />
                      <Button
                        disabled={!selectedUser}
                        color="primary"
                        variant="contained"
                        onClick={(e) => {
                          onAddUser(e, selectedUser);
                          setSelectedUser(null);
                          setRefresh(Math.random() * 99999);
                        }}
                      >
                        ADD
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box my={2} style={{ maxHeight: 200, overflow: 'hidden' }}>
                    {selectedUsers.map((user) => (
                      <Box mb={2} mt={2} key={user.userID}>
                        <ShareListItem user={user} {...rest} />
                      </Box>
                    ))}
                  </Box>
                  {selectedUsers.length === 0 && (
                    <Box my={2}>
                      <Typography>Please add a user.</Typography>
                    </Box>
                  )}
                  {selectedUsers.some((x) => x?.accessDenied) && (
                    <Box my={2} className={classes.userItem}>
                      <Tooltip
                        title={
                          'Warning: User does not have access to all files'
                        }
                      >
                        <Warning className={classes.icon} />
                      </Tooltip>
                      <Typography>
                        Warning: User does not have access to all files
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item className={classes.buttons}>
                    <Button onClick={onCancel}>CANCEL</Button>
                    <Button
                      disabled={selectedUsers.length === 0}
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={(e) => onSendInvite(e, selectedUsers)}
                    >
                      {pluralize(selectedUsers.length, 'Send Invite')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ShareForm;
