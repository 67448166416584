import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useDebounce from 'src/hooks/useDebounce';
import { getClientByClientId, getClientsBySearchValue } from 'src/apis/client';
import Client from 'src/models/Client';
import LoadingScreen from 'src/components/LoadingScreen';
import { submitError } from 'src/components/Forms/FormikHelper';
interface Props {
  [key: string]: any;
  clientId?: number;
}

const ClientAutoComplete: FC<Props> = ({
  errors,
  touched,
  onBlur,
  onChange,
  disabled,
  required,
  filterOptions = (x) => x,
  extraQuery,
  helperText = '',
  clientId = 0
}) => {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounce<string>(
    '',
    500
  );

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<{ client: Client }>({ client: null });
  const [options, setOptions] = useState<Client[]>([]);

  const search = async (value) => {
    if (!value || value === '') return;
    setIsLoading(true);
    let clients = [];

    try {
      clients = await getClientsBySearchValue(value, extraQuery);
    } catch (err) {
      submitError(err, null, true);
    }
    if (clients.length !== 0) setOptions(clients);

    setIsLoading(false);
  };

  const retrieveExistingValueById = useCallback(async (id: number) => {
    setIsLoading(true);
    const client = await getClientByClientId(id);

    if (client) {
      setOptions([client]);
      setState({ client });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      state.client &&
      state.client.clientID &&
      options.length === 0 &&
      !isLoading
    ) {
      retrieveExistingValueById(state.client.clientID);
    }
  }, [state, options, retrieveExistingValueById]);

  useEffect(() => {
    if (debouncedInputValue.length > 2) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    // check for values that the back-end will throw an error and ignore
    if (!clientId || clientId === 0 || state.client) return;

    setState({
      client: new Client({ clientID: clientId })
    });
  }, [clientId, state]);

  return (
    <Autocomplete
      disabled={disabled}
      id="clients"
      filterOptions={filterOptions}
      autoComplete
      fullWidth
      options={options}
      value={state.client}
      loading={isLoading}
      getOptionSelected={(option, value) => option.clientID === value.clientID}
      getOptionLabel={(option) =>
        option.clientName ? `${option.clientName} / ${option.clientID}` : ''
      }
      renderOption={(option) => (
        <>
          {option.clientName} / {option.clientID}
        </>
      )}
      onBlur={onBlur}
      onChange={(e: object, client: Client | null) => {
        setState({ client });
        onChange(e, client ? client : new Client());
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="Client Name / Client ID"
          name="clientName"
          variant="outlined"
          error={Boolean(touched?.clientName && errors?.clientName)}
          helperText={
            (helperText && helperText) ||
            (touched?.clientName && errors?.clientName)
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <LoadingScreen style={{ width: 20 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default ClientAutoComplete;
