import { IUserAccess } from 'src/types/IUserAccess';
import faker from 'faker';
import { Roles } from 'src/types/IRole';

export default class UserAccess implements IUserAccess {
  userAccessID?: number;
  userName?: string;
  firstName: string = '';
  lastName: string = '';
  userEmail?: string;
  userRoleID?: number;
  userType?: string;
  clientName?: string;
  programName?: string;
  siteName?: string;
  projectName?: string;
  userID?: number;
  clientID?: number;
  programID?: string;
  siteID?: string;
  projectID?: string;
  admin?: string;
  readOnly?: string;
  confidentialAccess?: string;

  createdDate?: string;
  createdBy?: number;
  modifiedDate?: string;
  modifiedBy?: number;
  createdByName?: string;
  modifiedByName?: string;

  constructor(init?: Partial<IUserAccess>) {
    Object.assign(this, init);
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  generateMockData() {
    // this.firstName = faker.name.firstName();
    // this.lastName = faker.name.lastName();
    this.userRoleID = Roles.EXTERNAL_USER_FULL_ACCESS;
    this.userEmail = faker.internet.email();
  }
}
