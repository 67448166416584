import axios from 'axios';
import Client from 'src/models/Client';
import { IClient } from 'src/types/IClient';
import { ISummary } from 'src/types/ISummary';
import urlBuilder from 'src/utils/urlBuilder';
import { queryHelper } from './dynamic-query-helper';
interface IClientQuery {
  pageNumber: number;
  pagination: true;
  result: IClient[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const getClientsByQueryRaw = async (query: any) => {
  return await axios.post<IClientQuery>(
    urlBuilder('Client/GetClientsByDynamicQuery'),
    query
  );
};

export const getClientsByQuery = async (query: any, limit?: number) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1
    };
  }
  const response = await axios.post<IClientQuery>(
    urlBuilder('Client/GetClientsByDynamicQuery'),
    query
  );

  let clients: Client[] = response.data.result.map((x) =>
    Client.deserialize(x)
  );

  return clients;
};

export const getClientByClientId = async (clientId: number) => {
  const clients = await getClientsByQuery({
    where: queryHelper.getClient(clientId).getQuery()
  });

  return clients[0] || null;
};

export const getClientsByProgramId = async (query, programId: number) => {
  return await axios.post<IClient[]>(
    urlBuilder('Client/GetClientsByProgramId'),
    query,
    {
      params: {
        programId
      }
    }
  );
};

export const getClientsBySiteId = async (query, siteId: number) => {
  return await axios.post<IClient[]>(
    urlBuilder('Client/GetClientsBySiteId'),
    query,
    {
      params: {
        siteId
      }
    }
  );
};

export const getClientsByProjectId = async (query, projectId: number) => {
  return await axios.post<IClientQuery[]>(
    urlBuilder('Client/GetClientsByProjectId'),
    query,
    {
      params: {
        projectId
      }
    }
  );
};

export const getClientsBySearchValue = async (value: string, extraQuery?) => {
  let query = [];

  if (extraQuery) {
    query = extraQuery;
  }

  query = [
    ...query,
    {
      andOr: query.length === 0 ? '' : 'and',
      items: [
        {
          andOr: '',
          field: 'clientName',
          operator: 'contains',
          value
        },
        {
          andOr: 'or',
          field: 'clientID',
          operator: 'contains',
          value
        }
      ]
    }
  ];

  const clients = await getClientsByQuery({
    where: query,
    orderBy: [
      {
        fieldName: 'createdDate',
        orderType: 'desc'
      }
    ]
  });

  return clients;
};

export const getClientsByUserId = async (userId: number) => {
  const clients = await getClientsByQuery({
    pagination: true,
    pageNumber: 1,
    userId
  });

  return clients;
};

export const getClientSummaryByClientId = async (clientId: number) => {
  const response = await axios.get<ISummary>(
    urlBuilder('Client/GetClientSummaryByClientId'),
    {
      params: {
        clientId
      }
    }
  );

  return response.data;
};

export const createClient = async (client: IClient) => {
  const response = await axios.post<IClient>(
    urlBuilder('Client/AddClient'),
    client
  );

  return response.data;
};

export const deleteClientByClientId = async (clientId: number) => {
  await axios.post(urlBuilder('Client/DeleteClient'), null, {
    params: {
      id: clientId
    }
  });
};

export const updateClient = async (client: IClient) => {
  const response = await axios.post<IClient>(
    urlBuilder('Client/UpdateClient'),
    client,
    {
      params: {
        id: client.clientID
      }
    }
  );
  return new Client(Object.assign({}, client, response.data));
};

export const getClientImageByClientId = async (clientId: number) => {
  try {
    return await axios.get(urlBuilder('Client/GetClientLogo'), {
      responseType: 'arraybuffer',
      params: {
        clientId
      }
    });
  } catch (e) {}
};

export const postClientImageByClientId = async (
  clientId: number,
  file: File
) => {
  return axios.post(
    urlBuilder('Client/PostClientLogo'),
    Buffer.from(await file.arrayBuffer()),
    {
      headers: {
        'Content-Type': 'image/jpeg'
      },
      params: {
        clientId
      }
    }
  );
};
