import { IUser } from 'src/types/IUser';
import faker from 'faker';
import { Roles } from 'src/types/IRole';
import { getUserImageByUserId } from 'src/apis/user';
import imageEncode from 'src/utils/imageEncode';
import { getInitials } from 'src/utils/string';
import { removeEmpty, serializeObj } from 'src/utils/object';

export default class User implements IUser {
  readonly id?: string;
  userID?: number;
  firstName: string = '';
  lastName: string = '';
  userName?: string;
  email: string = '';
  phoneNumber?: string;
  emailConfirmed?: boolean;
  password?: any;
  active?: boolean;
  roleID?: number = Roles.EXTERNAL_USER_READ_ONLY;
  notes?: any;
  avatar?: string;
  clientName?: string;
  address: string;
  city?: string = '';
  country?: string = '';
  state?: string;
  clientID?: number;
  passwordHash?: string;
  daysUntilPasswordExpires?: number;
  passwordActiveDays?: number;
  passwordChangedDate?: string;
  passwordExpiryDate?: string;
  isPasswordExpired?: boolean;
  programName?: string;
  confidentialAccess?: boolean;
  token?: string;
  simplifiedView?: boolean;
  userType?: string;
  notificationFlag?: boolean;
  finalFileUploadNotificationOption?: number;
  draftFileUploadNotificationOption?: number;
  notificationFrequencyID?: number;
  acceptedTermsAndConditions?: boolean;

  createdDate?: string;
  createdBy?: number;
  modifiedDate?: string;
  modifiedBy?: number;
  modifiedByName?: string;
  createdByName?: string;

  constructor(init?: Partial<IUser>) {
    Object.assign(this, init);
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  set fullName(value) {
    // do nothing
  }

  get initials(): string {
    return getInitials(this.fullName);
  }

  get isInternal(): boolean {
    return (
      this.roleID === Roles.INTERNAL_USER_ADMIN ||
      this.roleID === Roles.INTERNAL_USER_FULL_ACCESS ||
      this.roleID === Roles.INTERNAL_USER_READ_ONLY
    );
  }

  get isExternal(): boolean {
    return (
      this.roleID === Roles.EXTERNAL_USER_ADMIN ||
      this.roleID === Roles.EXTERNAL_USER_FULL_ACCESS ||
      this.roleID === Roles.EXTERNAL_USER_READ_ONLY
    );
  }

  get isAdmin(): boolean {
    return (
      this.roleID === Roles.EXTERNAL_USER_ADMIN ||
      this.roleID === Roles.INTERNAL_USER_ADMIN
    );
  }

  get isReadOnly(): boolean {
    return (
      this.roleID === Roles.EXTERNAL_USER_READ_ONLY ||
      this.roleID === Roles.INTERNAL_USER_READ_ONLY
    );
  }

  generateMockData(): void {
    this.firstName = faker.name.firstName();
    this.lastName = faker.name.lastName();
    this.email = faker.internet.email();
    this.password = 'smash22!';
    //this.clientID = 1000243; // a pixel pet parlour
    this.passwordActiveDays = 10;
    this.notes = faker.lorem.paragraph();
  }

  async loadAvatar() {
    let response = null;

    try {
      response = await getUserImageByUserId(this.userID);
    } catch (e) {}

    this.avatar = response?.data ? imageEncode(response.data) : '';
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  clone(): User {
    return new User(serializeObj(this));
  }

  // public static deserialize(init?: Partial<IUser>) {
  //   let user = new User();

  //   Object.assign(this, init);

  //   return user;
  // }

  // public static deserialize(data: IUser) {
  //   let user = new User();

  //   for (let key in data) {
  //     user[key] = data[key];
  //   }

  //   user.createdDate = format(data.createdDate);
  //   user.modifiedDate = format(data.modifiedDate);

  //   return user;
  // }
}
