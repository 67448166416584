import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';

import { reducer, IState, defaultState, controller } from './@state';
import { IController } from './@state/controller';
import { eventEmitter } from 'src/utils/reducer/middleware/eventEmitter';
import { useReducerMiddleware } from 'src/utils/reducer/middleware/useReducerMiddleware';
import { isObjectEmpty } from 'src/utils/object';
import { log } from 'src/utils/reducer/middleware/log';
interface IContext {
  controller?: IController;
  state?: IState | null;
  ref?: any;
}

const ProjectsContext = createContext<IContext>({});

const useProjects = (): IContext => {
  const context = useContext(ProjectsContext);
  if (isObjectEmpty(context)) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const ProjectsProvider: React.FC<ProviderProps> = ({
  children
}: ProviderProps) => {
  const [state, dispatch] = useReducerMiddleware<IState>(
    reducer,
    defaultState,
    [eventEmitter]
  );

  const _controller = controller(dispatch, state);

  return (
    <ProjectsContext.Provider value={{ state, controller: _controller }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export { ProjectsProvider, useProjects };
