import axios from 'axios';
import { queryHelper } from 'src/apis/dynamic-query-helper';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import urlBuilder from 'src/utils/urlBuilder';
import { ACTIONS } from './actions';
import { IState } from './state';
import { Column } from 'src/components/Tables/@types/column';
import { Filter } from 'src/components/Tables/@hooks/useFilters';
import { Sorting } from '@devexpress/dx-react-grid';
import { IUser } from '../@types/IUser';

export interface IController {
  state: IState;
  init: (state: IState) => IController;
  fetchUsers: () => Promise<any>;
  deleteUser: (user: IUser) => Promise<any>;
  setSearchParams: (params: IUserParams) => IController;
  setPager: (params: IPager) => IController;
  cancelPendingRequests: () => void;
  destroy: () => void;
}

export interface IUserParams {
  activeRecord?: boolean;
  searchValue?: string;
  clientId?: number;
  programId?: number;
  siteId?: number;
  projectId?: number;
  userId?: number;
  currentUserId?: number;
  visibleColumns?: Column[];
  filters?: Filter[];
  sorting?: Sorting[];
  showConfidentialOnly?: boolean;
  isInternal?: boolean;
  isConfidential?: boolean;
  showExternalOnly?: boolean;
}

interface IUserQueryResponse {
  pageNumber: number;
  result: IUser[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}

export const controller = (dispatch: any, state: IState): IController => {
  return {
    state,
    init: function (state: IState): IController {
      // TODO: find beter way to merge
      this.state = {
        ...this.state,
        params: {
          ...this.state.params,
          ...state.params
        }
      };

      dispatch(ACTIONS.initialize(this.state));

      return this;
    },
    setSearchParams: function (params: IUserParams) {
      this.state = {
        ...this.state,
        params: {
          ...this.state.params,
          ...params
        }
      };

      dispatch(ACTIONS.setSearchParams(params));

      return this;
    },
    deleteUser: (user: IUser) => {
      return axios
        .post(urlBuilder('User/DeleteUser'), null, {
          params: {
            id: user.userID
          }
        })
        .then((resp) => {
          dispatch(ACTIONS.deleteUser(user));
          return resp.data;
        })
        .catch((error) => {
          console.log('error fetching consumed', error);
          return Promise.reject(error);
        });
    },
    setPager: function (pager: IPager) {
      dispatch(ACTIONS.setPagination(pager));

      this.state = {
        ...this.state,
        pagination: pager
      };

      return this;
    },
    fetchUsers: function () {
      dispatch(ACTIONS.loading());
      const { params, pagination } = this.state;

      this.cancelPendingRequests();

      const cancelTokenSource = axios.CancelToken.source();
      dispatch(ACTIONS.setCancelToken(cancelTokenSource));

      let query = [];

      if (params.activeRecord)
        query = queryHelper
          .getActiveRecord(params.activeRecord, query)
          .getQuery();

      if (
        (params.isInternal && !params.isConfidential) ||
        params.showExternalOnly
      )
        query = queryHelper.getExternal(query).getQuery();

      if (params.showConfidentialOnly)
        query = queryHelper.getConfidential(true, query).getQuery();

      if (params.filters.length > 0) {
        query = queryHelper.addFilters(params.filters, query).getQuery();
      }
      if (params.searchValue) {
        query = queryHelper
          .searchColumns(
            params.visibleColumns.filter((x) => !x.exclude),
            params.searchValue,
            query
          )
          .getQuery();
      }

      const baseQuery = {
        userId: params.currentUserId || params.userId,
        pagination: true,
        rowsPerPage: pagination.limit,
        pageNumber: pagination.offset + 1,
        where: query,
        orderBy: params.sorting.map(({ columnName, direction }) => ({
          fieldName: columnName,
          orderType: direction
        }))
      };

      if (params.clientId) {
        return axios
          .post<IUserQueryResponse>(
            urlBuilder('User/GetUsersByClientId'),
            baseQuery,
            {
              params: {
                clientId: params.clientId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setUsers(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      if (params.programId) {
        return axios
          .post<IUserQueryResponse>(
            urlBuilder('User/GetUsersByProgramId'),
            baseQuery,
            {
              params: {
                programId: params.programId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setUsers(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      if (params.siteId) {
        return axios
          .post<IUserQueryResponse>(
            urlBuilder('User/GetUsersBySiteId'),
            baseQuery,
            {
              params: {
                siteId: params.siteId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setUsers(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      if (params.projectId) {
        return axios
          .post<IUserQueryResponse>(
            urlBuilder('User/GetUsersByProjectId'),
            baseQuery,
            {
              params: {
                projectId: params.projectId
              },
              cancelToken: cancelTokenSource.token
            }
          )
          .then((resp) => {
            dispatch(
              ACTIONS.setUsers(resp.data.result, resp.data.totalRecords)
            );
            return resp.data.result;
          })
          .catch((error) => {
            console.log('error fetching consumed', error);
            return Promise.reject(error);
          });
      }

      // return axios
      //   .post<IUserQueryResponse>(
      //     urlBuilder('User/GetUsersByDynamicQuery'),
      //     baseQuery,
      //     {
      //       cancelToken: cancelTokenSource.token
      //     }
      //   )
      //   .then((resp) => {
      //     dispatch(ACTIONS.setUsers(resp.data.result, resp.data.totalRecords));
      //     return resp.data.result;
      //   })
      //   .catch((error) => {
      //     console.log('error fetching consumed', error);
      //     return Promise.reject(error);
      //   });
    },
    cancelPendingRequests: function () {
      console.log('apiCancelToken', this.state.apiCancelToken);

      this.state.apiCancelToken &&
        this.state.apiCancelToken.cancel(
          'Operation canceled due to new request.'
        );
    },
    destroy: function () {
      this.cancelPendingRequests();
      //dispatch(ACTIONS.setState(defaultState));
    }
  };
};
