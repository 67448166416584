import type { CancelTokenSource } from 'axios';
import { IPager } from 'src/components/Tables/@hooks/usePagination';
import type IAction from 'src/types/IAction';
import type { IDocument } from 'src/types/IDocument';
import { IDocumentSummary } from 'src/types/IDocumentSummary';
import type { IDocumentParams } from './controller';
import type { IState } from './state';

export enum ACTION_TYPES {
  INITIALIZE = 'INITIALIZE',
  LOADING = 'LOADING',
  SET_STATE = 'SET_STATE',
  CANCEL_TOKEN_SOURCE = 'CANCEL_TOKEN_SOURCE',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_DOCUMENTS = 'GET_DOCUMENTS',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS',
  SET_DOCUMENTS_SUMMARY = 'SET_DOCUMENTS_SUMMARY'
}

export const ACTIONS = {
  loading: (): IAction => ({ type: ACTION_TYPES.LOADING, payload: true }),
  setState: (state: IState): IAction => ({
    type: ACTION_TYPES.SET_STATE,
    payload: state
  }),
  initialize: (state: IState): IAction => ({
    type: ACTION_TYPES.INITIALIZE,
    payload: state
  }),
  setCancelToken: (cancelToken: CancelTokenSource): IAction => ({
    type: ACTION_TYPES.CANCEL_TOKEN_SOURCE,
    payload: cancelToken
  }),
  setPagination: (pagination: IPager): IAction => ({
    type: ACTION_TYPES.SET_PAGINATION,
    payload: pagination
  }),
  setDocumentsSummary: (documentsSummary: IDocumentSummary[]): IAction => ({
    type: ACTION_TYPES.SET_DOCUMENTS_SUMMARY,
    payload: {
      documentsSummary
    }
  }),
  setDocuments: (
    documents: IDocument[] = [],
    totalRecords: number = 0
  ): IAction => ({
    type: ACTION_TYPES.SET_DOCUMENTS,
    payload: {
      documents,
      totalRecords
    }
  }),
  deleteDocument: (document: IDocument): IAction => ({
    type: ACTION_TYPES.DELETE_DOCUMENT,
    payload: document
  }),

  setSearchParams: (params: IDocumentParams): IAction => ({
    type: ACTION_TYPES.SET_SEARCH_PARAMS,
    payload: params
  })
};
