import React, { FC } from "react";
import { Router } from "react-router-dom";

import DayJSUtils from "@date-io/dayjs";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import GlobalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import ServiceWorkerNotification from "src/components/ServiceWorkerNotification";
import SettingsNotification from "src/components/SettingsNotification";
import { AuthProvider } from "src/contexts/AuthContext";
import useSettings from "src/hooks/useSettings";
import routes, { renderRoutes } from "src/routes";
import { createTheme } from "src/theme";

import { DownloadFilesProvider } from "./contexts/DownloadFilesContext";
import { UserAccessProvider } from "./contexts/userAccess/Provider";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import { ClientsProvider } from "./views/clients/Provider";
import { DocumentsProvider } from "./views/files/Provider";
import { ProgramsProvider } from "./views/programs/Provider";
import { ProjectsProvider } from "./views/projects/Provider";
import { SitesProvider } from "./views/sites/Provider";
import { UsersProvider } from "./views/users/Provider";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DayJSUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <SnackbarUtilsConfigurator />
            <Router history={history}>
              <AuthProvider>
                <DocumentsProvider>
                  <ClientsProvider>
                    <ProgramsProvider>
                      <SitesProvider>
                        <ProjectsProvider>
                          <UsersProvider>
                            <UserAccessProvider>
                              <GlobalStyles />
                              <ScrollReset />
                              <SettingsNotification />
                              <ServiceWorkerNotification />
                              <DownloadFilesProvider>
                                {renderRoutes(routes)}
                              </DownloadFilesProvider>
                            </UserAccessProvider>
                          </UsersProvider>
                        </ProjectsProvider>
                      </SitesProvider>
                    </ProgramsProvider>
                  </ClientsProvider>
                </DocumentsProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
