import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useDebounce from 'src/hooks/useDebounce';
import { getUserByUserId, getUsersBySearchValue } from 'src/apis/user';
import User from 'src/models/User';
import LoadingScreen from 'src/components/LoadingScreen';
import { submitError } from 'src/components/Forms/FormikHelper';

interface Props {
  [key: string]: any;
  clientId?: number;
}

const UserAutoComplete: FC<Props> = ({
  errors,
  touched,
  onBlur,
  onChange,
  disabled,
  required,
  label,
  filterOptions = (x) => x,
  extraQuery,
  userId = 0,
  id = 'username'
}) => {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounce<string>(
    '',
    500
  );

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<{ user: User }>({ user: null });
  const [options, setOptions] = useState<User[]>([]);

  const search = async (value) => {
    if (!value || value === '') return;

    setIsLoading(true);
    let users = [];
    try {
      users = await getUsersBySearchValue(value, extraQuery);
    } catch (err) {
      submitError(err, null, true);
    }
    if (users.length !== 0) setOptions(users);
    setIsLoading(false);
  };

  const retrieveExistingValueById = useCallback(async (id: number) => {
    setIsLoading(true);
    const user = await getUserByUserId(id);

    if (user) {
      setOptions([user]);
      setState({ user });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (state.user && state.user.userID && options.length === 0 && !isLoading) {
      retrieveExistingValueById(state.user.userID);
    }
  }, [state, options, retrieveExistingValueById]);

  useEffect(() => {
    if (debouncedInputValue.length > 2) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    // check for values that the back-end will throw an error and ignore
    if (!userId || userId === 0 || state.user) return;

    setState({
      user: new User({ userID: userId })
    });
  }, [userId, state]);

  return (
    <Autocomplete
      disabled={disabled}
      id={id}
      filterOptions={filterOptions}
      autoComplete
      fullWidth
      options={options}
      value={state.user}
      loading={isLoading}
      getOptionSelected={(option, value) => option.userID === value.userID}
      getOptionLabel={(option) =>
        option.userName ? `${option.fullName} / ${option.email}` : ''
      }
      renderOption={(option) => (
        <>
          {option.fullName} / {option.email}
        </>
      )}
      onBlur={onBlur}
      onChange={(e: object, user: User | null) => {
        setState({ user });
        onChange(e, user ? user : new User());
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          name={id}
          variant="outlined"
          error={Boolean(touched[id] && errors[id])}
          helperText={touched[id] && errors[id]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <LoadingScreen style={{ width: 20 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default UserAutoComplete;
