import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import useDebounce from 'src/hooks/useDebounce';
import {
  getProgramByProgramId,
  getProgramsBySearchValue
} from 'src/apis/program';
import Program from 'src/models/Program';
import LoadingScreen from 'src/components/LoadingScreen';
import { submitError } from 'src/components/Forms/FormikHelper';

interface Props {
  [key: string]: any;
  programId?: number;
}

const ProgramAutoComplete: FC<Props> = ({
  errors,
  touched,
  onBlur,
  onChange,
  disabled,
  required,
  filterOptions = (x) => x,
  extraQuery,
  programId = 0
}) => {
  const [debouncedInputValue, inputValue, setInputValue] = useDebounce<string>(
    '',
    500
  );

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<{ program: Program }>({ program: null });
  const [options, setOptions] = useState<Program[]>([]);

  const search = async (value) => {
    if (!value || value === '') return;

    setIsLoading(true);
    let programs = [];

    try {
      programs = await getProgramsBySearchValue(value, extraQuery);
    } catch (err) {
      submitError(err, null, true);
    }
    if (programs.length !== 0) setOptions(programs);
    setIsLoading(false);
  };

  const retrieveExistingValueById = useCallback(async (id: number) => {
    setIsLoading(true);
    const program = await getProgramByProgramId(id);
    if (program) {
      setOptions([program]);
      setState({ program });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      state.program &&
      state.program.programID !== 0 &&
      options.length === 0 &&
      !isLoading
    ) {
      retrieveExistingValueById(state.program.programID);
    }
  }, [state, options, retrieveExistingValueById]);

  useEffect(() => {
    if (debouncedInputValue.length > 2) {
      search(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    // check for values that the back-end will throw an error and ignore
    let id = programId === null ? 0 : programId;

    if (id !== state.program?.programID) {
      setState({
        program: new Program({ programID: id })
      });
    }
  }, [programId, state]);

  return (
    <Autocomplete
      disabled={disabled}
      id="programs"
      filterOptions={filterOptions}
      autoComplete
      fullWidth
      options={options}
      value={state.program}
      loading={isLoading}
      getOptionSelected={(option, value) =>
        option.programID === value.programID
      }
      getOptionLabel={(option) =>
        option.programName
          ? `${option.programName} / ${option.programCode}`
          : ''
      }
      renderOption={(option) => (
        <>
          {option.programName} / {option.programCode}
        </>
      )}
      onBlur={onBlur}
      onChange={(e: object, program: Program | null) => {
        setState({ program });
        onChange(e, program ? program : new Program());
      }}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="Program Name / Program Code"
          name="programName"
          variant="outlined"
          error={Boolean(touched?.programName && errors?.programName)}
          helperText={touched?.programName && errors?.programName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <LoadingScreen style={{ width: 20 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default ProgramAutoComplete;
