import React, { useReducer } from 'react';

import type { Dispatch } from 'react';
import type IAction from 'src/types/IAction';

export interface IMiddleware<T = any> {
  id?: string;
  state?: T;
  action?: IAction;
  dispatch?: Dispatch<IAction>;
}

export const useReducerMiddleware = <T>(
  reducer: (state: T, action: IAction) => T,
  defaultState: T,
  middleware: Array<(params: IMiddleware<T>) => void>,
  id?: string
): [T, (action: IAction) => void] => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  // The middleware function
  const dispatcMiddleware = (action: IAction): void => {
    middleware.forEach((x) => x({ id, state, action, dispatch }));
    dispatch(action);
  };

  return [state, dispatcMiddleware];
};
