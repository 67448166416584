import React, { useState } from 'react';
import type { FC } from 'react';

import { makeStyles, Card, Backdrop, Box } from '@material-ui/core';
import type { Theme } from 'src/theme';
import { useDispatch, useSelector } from 'src/store';
import { closeModal, uploadFile } from 'src/slices/batch-csv-manager';
import UploadBatchCSVFileForm from './UploadBatchCSVFileForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  card: {
    minWidth: '25%',
    maxWidth: '80%',
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  }
}));

const BatchCSVManger: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { response, isModalOpen, level } = useSelector(
    (state) => state.batchCsvUpload
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Backdrop
      className={classes.backdrop}
      open={isModalOpen}
      onClick={() => {}}
    >
      <Card className={classes.card} onClick={(e) => e.stopPropagation()}>
        <Box m={2}>
          <UploadBatchCSVFileForm
            onClose={handleClose}
            message={response?.message}
            code={response?.code}
            data={response?.data}
            level={level}
          />
        </Box>
      </Card>
    </Backdrop>
  );
};

export default BatchCSVManger;
