import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';

import { reducer, IState, defaultState, controller } from './@state';
import { IController } from './@state/controller';
import { eventEmitter } from 'src/utils/reducer/middleware/eventEmitter';
import { useReducerMiddleware } from 'src/utils/reducer/middleware/useReducerMiddleware';
import { isObjectEmpty } from 'src/utils/object';
interface IContext {
  controller?: IController;
  state?: IState | null;
  ref?: any;
}

const ProgramsContext = createContext<IContext>({});

const usePrograms = (): IContext => {
  const context = useContext(ProgramsContext);
  if (isObjectEmpty(context)) {
    throw new Error('usePrograms must be used within a ProgramsProvider');
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const ProgramsProvider: React.FC<ProviderProps> = ({
  children
}: ProviderProps) => {
  const [state, dispatch] = useReducerMiddleware<IState>(
    reducer,
    defaultState,
    [eventEmitter]
  );

  const _controller = controller(dispatch, state);

  return (
    <ProgramsContext.Provider value={{ state, controller: _controller }}>
      {children}
    </ProgramsContext.Provider>
  );
};

export { ProgramsProvider, usePrograms };
