import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Tooltip } from '@material-ui/core';

import csvIcon from 'src/assets/icons/file-csv.svg';
import excelIcon from 'src/assets/icons/file-excel.svg';
import genericIcon from 'src/assets/icons/file-generic.svg';
import htmlIcon from 'src/assets/icons/file-html.svg';
import imageIcon from 'src/assets/icons/file-image.svg';
import pdfIcon from 'src/assets/icons/file-pdf.svg';
import powerpointIcon from 'src/assets/icons/file-powerpoint.svg';
import wordIcon from 'src/assets/icons/file-word.svg';
import zipIcon from 'src/assets/icons/file-zip.svg';

interface Props {
  [key: string]: any;
  file?: string;
  mimeType?: string;
}

const fileTypes = {
  html: htmlIcon,
  'text/html': htmlIcon,
  xlsx: excelIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
  'application/vnd.ms-excel': excelIcon,
  csv: csvIcon,
  'text/csv': csvIcon,
  png: imageIcon,
  'image/png': imageIcon,
  jpeg: imageIcon,
  'image/jpeg': imageIcon,
  jpg: imageIcon,
  'image/jpg': imageIcon,
  bmp: imageIcon,
  'image/bmp': imageIcon,
  gif: imageIcon,
  'image/gif': imageIcon,
  pdf: pdfIcon,
  'application/pdf': pdfIcon,
  ppt: powerpointIcon,
  'application/vnd.ms-powerpoint': powerpointIcon,
  pptx: powerpointIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': powerpointIcon,
  doc: wordIcon,
  'application/msword': wordIcon,
  docx: wordIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': wordIcon,
  zip: zipIcon,
  'application/zip': zipIcon,
  '7z': zipIcon,
  'application/x-7z-compressed': zipIcon,
  arc: zipIcon,
  'application/x-freearc': zipIcon,
  bz2: zipIcon,
  'application/x-bzip': zipIcon,
  'application/x-bzip2': zipIcon,
  gz: zipIcon,
  'application/gzip': zipIcon,
  rar: zipIcon,
  'application/vnd.rar': zipIcon,
  tar: zipIcon,
  'application/x-tar': zipIcon
};

const FileIcon: FC<Props> = ({ file, mimeType, width = 28, height = 28 }) => {
  let src = genericIcon;
  let ext = '';
  let _file = null;
  let _mimeType = null;
  let reg = null;
  let title = 'Generic File';

  if (file) {
    _file = file?.toLowerCase();
    title = _file;
    reg = /[^.]+$/.exec(_file);
    ext = reg[0];
    src = fileTypes[ext];
  }

  if (mimeType) {
    _mimeType = mimeType?.toLowerCase();
    title = _mimeType;
    src = fileTypes[_mimeType];
  }

  return (
    <Tooltip title={title}>
      <img
        src={src || genericIcon}
        width={width}
        height={height}
        alt={`File Icon`}
      />
    </Tooltip>
  );
};

export default FileIcon;
