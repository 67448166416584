import { IClient } from 'src/types/IClient';
import faker from 'faker';
import { format } from 'src/utils/date';
import { removeEmpty, serializeObj } from 'src/utils/object';
import { getClientImageByClientId } from 'src/apis/client';
import imageEncode from 'src/utils/imageEncode';

export default class Client implements IClient {
  confidential?: boolean;
  clientID: number = 0;
  clientName: string;
  clientCode: string;
  clientTypeID: number;
  contactName?: string;
  phone?: string;
  webSite?: string;
  address: string;
  address1?: string;
  fullAddress?: string = '';
  city: string;
  state: string;
  country: string;
  zipCode: string;
  active?: boolean;
  quireID?: number;
  quireURL?: string;
  visionID?: number;
  visionURL?: string;
  assetCalcID?: number;
  assetCalcURL?: string;
  proTrackID?: number;
  proTrackURL?: string;
  notes?: string;
  salesForceID?: number;
  referenceNumber?: string;
  createdDate?: string;
  createdBy?: number;
  modifiedDate?: string;
  modifiedBy?: number;
  modifiedByName?: string;
  createdByName?: string;
  contactEmail: string;
  contactPhone: string;
  csRep?: string;
  csRepEmail?: string;
  csRepID?: number;
  csRepPhone?: string;
  bvSalesLead?: string;
  bvSalesLeadEmail?: string;
  bvSalesLeadID?: number;
  bvSalesLeadPhone?: string;
  avatar?: string;

  constructor(init?: Partial<IClient>) {
    Object.assign(this, init);
  }

  get completeAddress(): string {
    const fullAddress =
      this.fullAddress !== ''
        ? this.fullAddress
        : `${this.address} ${this.address1}`;
    return `${fullAddress.trim()}, ${this.city} ${this.state}, ${
      this.country
    } ${this.zipCode} `;
  }

  generateMockData() {
    //required fields
    this.clientName = faker.company.companyName() + ' - SMASH';
    this.clientID = faker.random.number();
    this.notes = faker.lorem.paragraph();
    this.city = 'Toronto';
    this.state = 'ON';
    this.zipCode = 'L9Y2Z4';
    this.country = 'CA';
    this.clientCode = String(faker.random.number());
    this.address = faker.address.streetAddress();
    this.clientTypeID = 1;

    this.contactEmail = faker.internet.email();
    this.contactPhone = faker.phone.phoneNumber();
    this.contactName = faker.name.findName();

    this.bvSalesLead = faker.name.findName();
    this.bvSalesLeadEmail = faker.internet.email();

    this.avatar =
      Math.floor(Math.random() * 10) > 5 ? 'https://picsum.photos/200' : '';
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  async loadAvatar() {
    let response = null;

    try {
      response = await getClientImageByClientId(this.clientID);
    } catch (e) {}

    this.avatar = response?.data ? imageEncode(response.data) : '';
  }

  public static deserialize(data: IClient) {
    let client = new Client();

    for (let key in data) {
      client[key] = data[key];
    }

    client.createdDate = format(data.createdDate);
    client.modifiedDate = format(data.modifiedDate);

    return client;
  }
}
